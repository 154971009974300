import {
  loadInvestmentProductBannerInfoThunk,
  loadInvestmentGroups,
  loadInvestmentProductBrandingDataThunk,
  loadInvestmentProductESGDataThunk,
  loadInvestmentProductManagerThunk,
  loadInvestmentProductSeriesThunk,
  updateInvestmentProductBannerInfo,
  loadInvestmentProductModelsThunk
} from "@redux/slices/product/investment-product";
import { useAppDispatch } from "@redux/store";
import { useNotification } from "./useNotification";
import {
  ProductBannerType,
  TopNotificationItem,
  TopNotificationType,
} from "@components/top-notifications/model";
import { format } from "date-fns";
import { DateFormat, TimeFormat } from "@constants/format";
import { updatePlatformProfileNotifications } from "@redux/slices/top-notifications";
import { InvestmentProductBannerInfoDTO } from "@models/product/investment-product/entities/bannerInfo";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { LocalStorage } from "@constants";
import { useUser } from "@hooks/useUser";
import { useProduct } from "@hooks/useProduct";

export const useInvestmentProduct = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  const { user } = useUser();
  const { isShowProductBanner } = useProduct();

  const investmentProductBannerItemKey = `${LocalStorage.investmentProductBannerInfo}+${user?.auth0Id}`;

  const loadInvestmentProductManager = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductManagerThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductSeries = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductSeriesThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductModels = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductModelsThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductEsg = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductESGDataThunk({ investmentProductId, mode }),
    ).unwrap();

    if (![response, response.isSuccess, response.data].every(Boolean)) {
      notify(response.message, {
        variant: "error",
        close: true,
      });

      return;
    }

    return response.data;
  };

  const loadInvestmentProductBrandingInfo = async (productId: string) => {
    const response = await dispatch(
      loadInvestmentProductBrandingDataThunk(productId),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadInvestmentProductBannerInfo = async (productId: string) => {
    const response = await dispatch(
      loadInvestmentProductBannerInfoThunk(productId),
    ).unwrap();
    if (response.isSuccess) {
      if (response.data) {
        const bannerInfo = response.data as InvestmentProductBannerInfoDTO[];
        dispatch(updateInvestmentProductBannerInfo(bannerInfo));
        const notifications = bannerInfo?.map(
          (item: InvestmentProductBannerInfoDTO) => {
            let notification = {} as TopNotificationItem;
            const lastModifiedDate = item.lastModifiedDate ?? new Date();

            switch (item.status) {
              case "Draft":
                notification = {
                  ...notification,
                  title:
                    "Draft in progress. Please follow orange dots to find the changes.",
                  productBannerType: ProductBannerType.Draft,
                  message: `Last updated by ${
                    item.lastUpdatedUserName
                  } at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Missing data":
                notification = {
                  ...notification,
                  title:
                    "One or more fields are missing data. Please follow the blue dots.",
                  productBannerType: ProductBannerType.MissingData,
                  message: `We added or modified fields at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}. Please follow the blue dots to complete the missing fields.`,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Pending approval":
                notification = {
                  ...notification,
                  title: "This platform profile is frozen.",
                  productBannerType: ProductBannerType.Pending,
                  message: `The SuitabilityHub team is now reviewing the changes that were were submitted at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}. Please contact us if you have any questions.`,
                  type: TopNotificationType.Warning,
                };
                break;
              case "Approved":
                notification = {
                  ...notification,
                  title: "Latest submission was approved.",
                  productBannerType: ProductBannerType.Approved,
                  message: `Profile approved at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  type: TopNotificationType.Success,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Rejected":
                notification = {
                  ...notification,
                  title:
                    "Latest submission was not accepted and not published.",
                  productBannerType: ProductBannerType.Rejected,
                  message: `Profile reverted back to draft at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  type: TopNotificationType.Error,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
            }
            return notification;
          },
        );

        const investmentProductBannerInfo = getLocalStorageItem(investmentProductBannerItemKey);

        if (investmentProductBannerInfo) {
          if (!Object.keys(investmentProductBannerInfo).includes(productId)) {
            setLocalStorageItem(investmentProductBannerItemKey, {
              ...investmentProductBannerInfo!,
              [productId]: notifications
                .filter((notification) =>
                  Object.keys(notification).includes("productId"),
                )
                .map((notification) => {
                  return {
                    ...notification,
                    show: true,
                  };
                }),
            });
          } else {
            const newNotifications = notifications.filter((notification) =>
              Object.keys(notification).includes("productId"),
            );
            if (newNotifications.length > 0) {
              setLocalStorageItem(investmentProductBannerItemKey, {
                ...investmentProductBannerInfo!,
                [productId]: newNotifications.map((notification) => {
                  return {
                    ...notification,
                    show: isShowProductBanner(
                      newNotifications,
                      notification,
                      investmentProductBannerInfo[productId],
                      notification.productBannerType,
                    ),
                  };
                }),
              });
            }
          }
        } else {
          setLocalStorageItem(investmentProductBannerItemKey, {
            [productId]: notifications
              .filter((notification) =>
                Object.keys(notification).includes("productId"),
              )
              .map((notification) => ({
                ...notification,
                show: true,
              })),
          });
        }

        dispatch(updatePlatformProfileNotifications(notifications));
        return;
      }
    } else {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };

  const loadInvestmentProductGroups = async (
    productId?: string,
    version?: string,
    configurationVersion?: string,
    investmentConfigurationVersion?: string,
  ) => {
    const response = await dispatch(
      loadInvestmentGroups({ productId, version, configurationVersion, investmentConfigurationVersion }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };

  return {
    loadInvestmentProductManager,
    loadInvestmentProductSeries,
    loadInvestmentProductModels,
    loadInvestmentProductBrandingInfo,
    loadInvestmentProductEsg,
    loadInvestmentProductBannerInfo,
    loadInvestmentProductGroups,
  };
};
