import {
  SHTableCell,
  SHTableCellProps,
  SHTypography,
} from "@components/design-systems";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { isNumber } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Variant } from "@mui/material/styles/createTypography";

export interface FeeAnalysisTableCellProps {
  isFirstColumn?: boolean;
  colorCell?: "Green" | "Gray" | "Orange" | "White";
}

export const FeeAnalysisTableCell = React.memo(
  ({
    isFirstColumn,
    colorCell,
    ...others
  }: FeeAnalysisTableCellProps & SHTableCellProps) => {
    const { palette } = useTheme();
    const {
      ui: { isShirkColumn },
    } = useSelector((state: RootState) => state.review);

    const cellWidth = useMemo(() => {
      return isFirstColumn
        ? isShirkColumn
          ? 290
          : 316
        : isShirkColumn
        ? 192
        : 202;
    }, [isFirstColumn, isShirkColumn]);

    // TODO: Add to palette
    const colorCellMapping: {
      [key in "Green" | "Gray" | "Orange" | "White"]?: string;
    } = {
      Green: "#EEFAF7",
      Gray: "#E0E5F0",
      Orange: "#FFF5F5",
      White: "#FFFFFF",
    };

    return (
      <SHTableCell
        sx={{
          left: 0,
          width: cellWidth,
          minWidth: cellWidth,
          maxWidth: cellWidth,
          position: "sticky",
          padding: "5px 12px",
          textAlign: "center",
          border: `1px solid ${palette.secondary[100]}`,
          backgroundColor: colorCell && colorCellMapping[colorCell],
        }}
        {...others}
      ></SHTableCell>
    );
  },
);

export interface RenderFeesValueProps {
  value?: string;
  valuePercentage?: string;
  tableViewMode?: FeesDisplayStyle;
  variant?: Variant;
}

export const RenderNumericFeesValue = ({
  value,
  valuePercentage,
  tableViewMode = FeesDisplayStyle.Dollar,
  variant = "body2",
}: RenderFeesValueProps) => {
  if (!isNumber(parseFloat(value ?? ""))) {
    return <SHTypography variant={variant}>{value}</SHTypography>;
  }

  var numberDollar = parseFloat(value ?? "0");
  var numberPercentage = parseFloat(valuePercentage ?? "0");

  if (isNaN(numberDollar) || isNaN(numberPercentage)) {
    return <>-</>;
  }

  if (tableViewMode === FeesDisplayStyle.Dollar || !valuePercentage) {
    const negativeValue = numberDollar < 0;
    var dollarValue = Math.abs(numberDollar);
    const data = Intl.NumberFormat().format(dollarValue);

    if (negativeValue) {
      return (
        <>
          {negativeValue && "-"}${data}
        </>
      );
    }
    return <>${data}</>;
  }

  const data = Intl.NumberFormat().format(numberPercentage);

  return <>{data}%</>;
};
