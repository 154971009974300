import { SHStack } from "@components/design-systems";
import { useUserPermissions } from "@hooks/userUserPermission";
import {
  AvailabilityFooterDisclaimer,
  ExcelTopBar,
  SmaAvailabilityTable,
  TableFunctionalBar,
} from "@pages/suppliers/_id/_products/investment-product/_id/view/components/availability";
import { UpgradeSubscriptionView } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/common";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import {
  CalculatedAvailabilityInvestmentDto,
  FilterCalculatedInvestmentDTO,
} from "@pages/managed-accounts/sma-list/components/tables/model";
import { initialFilterCalculatedInvestment } from "@pages/managed-accounts/sma-list/config";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";

interface TableContextType {
  originalTableData: CalculatedAvailabilityInvestmentDto[];
  setOriginalTableData: Dispatch<
    SetStateAction<CalculatedAvailabilityInvestmentDto[]>
  >;
  currentTableData: CalculatedAvailabilityInvestmentDto[];
  setCurrentTableData: Dispatch<
    SetStateAction<CalculatedAvailabilityInvestmentDto[]>
  >;
  tableFilter: FilterCalculatedInvestmentDTO;
  setTableFilter: Dispatch<SetStateAction<FilterCalculatedInvestmentDTO>>;
  selectedTableRowIds: string[];
  setSelectedTableRowIds: Dispatch<SetStateAction<string[]>>;
  isFetchingData: boolean;
  setIsFetchingData: Dispatch<SetStateAction<boolean>>;
  displayFeeType: FeesDisplayStyle;
  setDisplayFeeType: Dispatch<SetStateAction<FeesDisplayStyle>>;
}

export const TableContext = createContext<TableContextType>({
  originalTableData: [],
  setOriginalTableData: () => {},
  currentTableData: [],
  setCurrentTableData: () => {},
  tableFilter: initialFilterCalculatedInvestment,
  setTableFilter: () => {},
  selectedTableRowIds: [],
  setSelectedTableRowIds: () => {},
  isFetchingData: false,
  setIsFetchingData: () => {},
  displayFeeType: FeesDisplayStyle.Percentage,
  setDisplayFeeType: () => {},
});

const AvailabilityPageLayout = () => {
  const { isAdviserAdminTrial, isAdviserTrial } = useUserPermissions();

  const [originalTableData, setOriginalTableData] = useState<
    CalculatedAvailabilityInvestmentDto[]
  >([]);
  const [currentTableData, setCurrentTableData] = useState<
    CalculatedAvailabilityInvestmentDto[]
  >([]);
  const [tableFilter, setTableFilter] = useState<FilterCalculatedInvestmentDTO>(
    { ...initialFilterCalculatedInvestment },
  );
  const [selectedTableRowIds, setSelectedTableRowIds] = useState<string[]>([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [displayFeeType, setDisplayFeeType] = useState<FeesDisplayStyle>(
    FeesDisplayStyle.Percentage,
  );

  const tableContextProps = {
    originalTableData,
    setOriginalTableData,
    currentTableData,
    setCurrentTableData,
    tableFilter,
    setTableFilter,
    selectedTableRowIds,
    setSelectedTableRowIds,
    isFetchingData,
    setIsFetchingData,
    displayFeeType,
    setDisplayFeeType,
  };

  if (isAdviserAdminTrial || isAdviserTrial) {
    return <UpgradeSubscriptionView />;
  }

  return (
    <TableContext.Provider value={tableContextProps}>
      <SHStack
        display={"flex"}
        gridTemplateColumns={"6.6fr 3.25fr"}
        marginTop={"25px"}
        gap={"15px"}
      >
        <ExcelTopBar />
        <SHStack spacing={2}>
          <SHStack spacing={3}>
            <TableFunctionalBar />
            <SmaAvailabilityTable />
          </SHStack>
          <AvailabilityFooterDisclaimer />
        </SHStack>
      </SHStack>
    </TableContext.Provider>
  );
};

export default AvailabilityPageLayout;
