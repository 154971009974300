import { SHSearchBox, SHStack, SHTypography } from "@components/design-systems";
import { SMAListInvestmentInput } from "@pages/managed-accounts/sma-list/components/inputs/investment";
import {
  SMAListSetting,
  SMAListSettingsButton,
} from "@pages/managed-accounts/sma-list/components/buttons/settings";
import {
  SMAListFilter,
  SMAListFiltersButton,
} from "@pages/managed-accounts/sma-list/components/buttons/filters";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { getInvestmentFiltersPlatforms } from "@services/investment/investmentService";
import {
  SMAListCurrentlySelectedFilter,
  SMAListFavouriteFilter,
  SMAListMinimumInvestmentSizeFilter,
} from "@models/managed-accounts/enums/filter";
import { updateFilters } from "@redux/slices/managed-account/sma-list";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { TableContext } from "./AvailabilityPageLayout";
import {
  applySMAAvailabilityListFilters,
  combineModelName,
} from "@pages/managed-accounts/sma-list/util";
import { isEmpty } from "lodash";
import { CalculatedAvailabilityInvestmentDto } from "@pages/managed-accounts/sma-list/components/tables/model";

const TableFunctionalBar = () => {
  const dispatch = useDispatch();
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { investmentProductId } = useParams<{ investmentProductId: string }>();
  const {
    selectedTableRowIds,
    originalTableData,
    tableFilter,
    setCurrentTableData,
    setTableFilter,
    setIsFetchingData,
    setDisplayFeeType,
  } = useContext(TableContext);

  const [searchedInvestmentData, setSearchInvestmentData] = useState<
    CalculatedAvailabilityInvestmentDto[]
  >([]);
  const [filteredInvestmentData, setFilteredInvestmentData] = useState<
    CalculatedAvailabilityInvestmentDto[]
  >([]);

  useEffect(() => {
    handleOnChangeFilter(tableFilter.filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalTableData, selectedTableRowIds]);

  useEffectOnce(() => {
    getInvestmentFiltersPlatforms(investmentProductId).then((response) => {
      const initFilterData = {
        platforms: response?.data ?? [],
        favourites: SMAListFavouriteFilter.All,
        currentlySelected: SMAListCurrentlySelectedFilter.All,
        minimumInvestmentSize: SMAListMinimumInvestmentSizeFilter.All,
      };
      setTableFilter({ ...tableFilter, filters: initFilterData });
      dispatch(updateFilters(initFilterData));
    });
  });

  const handleOnSearch = (value: string | null) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    setIsFetchingData(true);

    searchTimeoutRef.current = setTimeout(() => {
      let searchedData: CalculatedAvailabilityInvestmentDto[] = [];

      if (isEmpty(tableFilter?.filters.platforms)) {
        setCurrentTableData(searchedData);
        setSearchInvestmentData(searchedData);
        setIsFetchingData(false);

        return;
      }

      if (value) {
        const lowerCaseValue = value.toLowerCase();
        const matchingSHCode = originalTableData
          .find((data) => data.platformCode.toLowerCase() === lowerCaseValue)
          ?.shCode?.toLowerCase();

        searchedData = originalTableData.filter((data) => {
          const dataMatches =
            data.managerName.toLowerCase().includes(lowerCaseValue) ||
            data.name.toLowerCase().includes(lowerCaseValue) ||
            data.shCode.toLowerCase().includes(lowerCaseValue) ||
            combineModelName(data.managerName, data.name, data.platformCode)
              .toLowerCase()
              .includes(lowerCaseValue);

          const shCodeMatches =
            matchingSHCode && data.shCode.toLowerCase() === matchingSHCode;

          return dataMatches || shCodeMatches;
        });
      }

      if (value && isEmpty(searchedData)) {
        setCurrentTableData([]);
      } else if (isEmpty(searchedData) && isEmpty(filteredInvestmentData)) {
        setCurrentTableData(originalTableData);
      } else if (isEmpty(searchedData) && !isEmpty(filteredInvestmentData)) {
        setCurrentTableData(
          originalTableData.filter((item) => {
            return filteredInvestmentData.some((filterItem) => {
              return filterItem.id === item.id;
            });
          }),
        );
      } else if (!isEmpty(searchedData) && isEmpty(filteredInvestmentData)) {
        setCurrentTableData(searchedData);
      } else if (!isEmpty(searchedData) && !isEmpty(filteredInvestmentData)) {
        setCurrentTableData(
          searchedData.filter((searchItem) => {
            return filteredInvestmentData.some((filterItem) => {
              return filterItem.id === searchItem.id;
            });
          }),
        );
      }

      setSearchInvestmentData(searchedData);
      setIsFetchingData(false);
    }, 600);
  };
  const handleOnChangeFilter = (filters?: SMAListFilter) => {
    if (!filters) return;
    setTableFilter({ ...tableFilter, filters: filters });

    let newCalculatedInvestmentData = applySMAAvailabilityListFilters(
      filters,
      selectedTableRowIds,
      originalTableData,
    );

    setFilteredInvestmentData(newCalculatedInvestmentData);

    if (!isEmpty(searchedInvestmentData)) {
      newCalculatedInvestmentData = newCalculatedInvestmentData.filter(
        (filterItem) => {
          return searchedInvestmentData.some((searchItem) => {
            return searchItem.id === filterItem.id;
          });
        },
      );
    }

    setCurrentTableData(newCalculatedInvestmentData);
  };

  const handleOnChangeSettings = (settings?: SMAListSetting) => {
    if (!settings) return;

    /* Implement in near future
            const showHideColumns: { [key: string]: boolean } = {};
        
            Object.keys(MainHeaderColumn).forEach((header) => {
              const subHeaders = MainSubHeaderMapping[header];
        
              if (settings.visibilityColumns?.some((item) => item === header)) {
                subHeaders?.forEach((subHeader) => (showHideColumns[subHeader] = true));
              } else {
                subHeaders?.forEach(
                    (subHeader) => (showHideColumns[subHeader] = false),
                );
              }
            });
            setColumnVisibility(showHideColumns);
            */

    setDisplayFeeType(settings.feesDisplayStyle);
  };

  return (
    <SHStack
      className={"SMAAvailabilityListHead"}
      direction={"row"}
      alignItems={"center"}
      justifyContent="space-between"
      sx={{ transition: "all 0.5s ease" }}
    >
      <SHSearchBox
        onChange={handleOnSearch}
        placeholder="Search manager, model and code"
      />
      <SHStack
        flexDirection={"row"}
        alignItems={"center"}
        gap={"10px"}
        maxWidth={560}
      >
        <SMAListInvestmentInput
          onUpdate={(investmentSize) =>
            setTableFilter({ ...tableFilter, investmentSize: investmentSize })
          }
        />
        <SHTypography
          fontSize={25}
          paddingLeft={"5px"}
          colorVariant={"disabled"}
        >
          |
        </SHTypography>
        <SMAListSettingsButton
          hideColumnSection={true}
          onSettings={handleOnChangeSettings}
        />
        <SMAListFiltersButton onFilters={handleOnChangeFilter} />
      </SHStack>
    </SHStack>
  );
};

export default TableFunctionalBar;
