import { CopyButton } from "@components/buttons/copy";
import {
  SHAvatar,
  SHChip,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { ProductBDMUserDTO } from "@models/product/entities/product";
import { Link, useTheme } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
import { UserInformationCardRegions } from "./user-information-card-region";
import { UserInforMationCardTooltip } from "./user-information-card-tooltip";

export interface UserInformationCardProps extends SHStackProps {
  userInfo?: ProductBDMUserDTO;
  showUserType?: Boolean;
}

export const UserInformationCard = ({
  userInfo,
  showUserType = false,
  ...others
}: UserInformationCardProps) => {
  const { palette } = useTheme();

  return (
    <SHStack
      height={245}
      width={{ lg: "32.75%", md: "49.33%", sm: "49.3%" }}
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        padding: "10px",
        boxSizing: "border-box",
        backdropFilter: "blur(0.5px)",
        background: `${hexToRGBA(palette.common.white, 0.25)}`,
        border: `1px solid ${palette.secondary[100]}`,
      }}
      {...others}
    >
      <SHStack
        width={"100%"}
        flexDirection={"row"}
        alignItems={"flex-start"}
        sx={{
          gap: "10px",
        }}
        {...others}
      >
        <SHStack width="75%" gap={"5px"}>
          <SHStack>
            <UserInforMationCardTooltip tooltipContent={userInfo?.name}>
              <SHTypography
                variant="h3"
                sx={{
                  padding: 0,
                  fontSize: 18,
                }}
              >
                {userInfo?.name}
              </SHTypography>
            </UserInforMationCardTooltip>

            {showUserType ? (
              <SHTypography
                variant="h3"
                colorVariant="third"
                sx={{ padding: 0, fontSize: 18 }}
              >
                {userInfo?.userType}
              </SHTypography>
            ) : (
              <></>
            )}

            <UserInforMationCardTooltip tooltipContent={userInfo?.jobTitle}>
              <SHTypography
                variant="body1"
                colorVariant="third"
                sx={{
                  padding: 0,
                }}
              >
                {userInfo?.jobTitle}
              </SHTypography>
            </UserInforMationCardTooltip>
          </SHStack>
          <SHStack>
            <SHTypography
              variant="body1"
              colorVariant="third"
              sx={{
                padding: 0,
              }}
            >
              {userInfo?.publicPhoneNumber}
            </SHTypography>

            {userInfo?.publicEmail && (
              <SHStack direction="row" gap={1}>
                <UserInforMationCardTooltip
                  tooltipContent={userInfo?.publicEmail}
                >
                  <Link
                    variant="body1"
                    component="a"
                    underline="none"
                    sx={{
                      padding: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      cursor: "pointer",
                      color: palette.text.secondary,
                      maxWidth: 230,
                    }}
                    href={`mailto:${userInfo?.publicEmail}`}
                  >
                    {userInfo?.publicEmail}
                  </Link>
                </UserInforMationCardTooltip>

                <CopyButton
                  tooltipTitle="Copy"
                  copyValue={userInfo?.publicEmail}
                  notifyContent="Email address copied to clipboard"
                />
              </SHStack>
            )}
          </SHStack>
          <SHStack>
            <SHTypography
              variant="subtitle2"
              colorVariant="third"
              sx={{
                padding: 0,
              }}
            >
              {userInfo?.preferredContactHours
                ? "Preferred contact hours"
                : null}
            </SHTypography>

            <UserInforMationCardTooltip
              tooltipContent={userInfo?.preferredContactHours}
            >
              <SHTypography
                variant="body3"
                colorVariant="third"
                sx={{
                  padding: 0,
                  textAlign: "justify",
                }}
              >
                {userInfo?.preferredContactHours}
              </SHTypography>
            </UserInforMationCardTooltip>
          </SHStack>
        </SHStack>
        <SHStack width={"25%"} alignItems={"center"} spacing={1}>
          <SHAvatar width="80px" height="80px" src={`${userInfo?.avatarUrl}`} />
          {userInfo?.linkedinProfileUrl && (
            <SHChip
              label={
                <Link
                  variant="body1"
                  component="a"
                  underline="none"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: 13,
                    fontWeight: 600,
                    cursor: "pointer",
                    color: palette.text.secondary,
                    "&:active": {
                      color: "white",
                    },
                  }}
                  onClick={() => window.open(`${userInfo?.linkedinProfileUrl}`)}
                >
                  LinkedIn
                </Link>
              }
              sx={{
                width: 80,
                height: 22,
                padding: "1px 6px",
                boxSizing: "border-box",
                background: `${hexToRGBA("#e9ebfd", 0.25)}`,
                border: `1px solid ${palette.secondary[100]}`,
                boxShadow: shadows[1],
                "& >.MuiChip-label": {
                  overflow: "unset",
                  paddingTop: "2px",
                },
                "&:hover": {
                  borderColor: palette.primary[800],
                  boxShadow: "none",
                },
                "&:active": {
                  backgroundColor: palette.primary.dark,
                  borderColor: palette.common.black,
                  boxShadow: `inset 0 4px 4px ${palette.primary[900]}`,
                },
              }}
            />
          )}
        </SHStack>
      </SHStack>

      <SHStack
        sx={{
          position: "absolute",
          top: "190px",
          width: "100%",
        }}
      >
        <SHTypography
          variant="subtitle2"
          colorVariant="third"
          sx={{
            padding: 0,
          }}
        >
          {!isEmpty(userInfo?.regions) ? "Region" : null}
        </SHTypography>

        <UserInformationCardRegions regions={userInfo?.regions} />
      </SHStack>
    </SHStack>
  );
};
