import { Components, Theme } from "@mui/material";
import { hexToRGBA } from "@utils";

export const muiTableRowTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiTableRow"> => ({
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderColor: hexToRGBA(theme.palette.primary[50], 0.3),
        borderStyle: "solid",
        borderWidth: 0,
        backgroundColor: theme.palette.background.default,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        "&:first-of-type": {
          borderTopWidth: 1,
        },
      },
    },
  },
});
