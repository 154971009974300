import { SHBox, SHDotGroupProps, SHStack } from "@components/design-systems";
import { TabLabel } from "@components/design-systems/sh-tabs/components/tab-label";
import { SHTabSkeleton } from "@components/skeletons/design-systems/SHTabSkeleton";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, TabProps, TabsProps, useTheme } from "@mui/material";
import { To } from "history";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { TabLink } from "./components/tab-link";

export interface SHTab extends TabProps {
  component?: React.ReactNode;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  linkTo?: To;
}
export interface SHTabsProps {
  tabs?: SHTab[];
  value?: string;
  onValueChange?: (value: string) => void;
  tabListProps?: TabsProps;
  isHandleByProps?: boolean;
  isLoading?: boolean;
}
export const SHTabs = ({
  value: propValue = "0",
  onValueChange,
  tabs = [],
  tabListProps,
  isHandleByProps,
  isLoading = false,
}: SHTabsProps): JSX.Element => {
  const [value, setValue] = useState(propValue);
  const { palette } = useTheme();

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
      if (typeof onValueChange === "function") onValueChange(newValue);
    },
    [onValueChange],
  );

  useEffect(() => {
    if (!isEqual(value, propValue)) setValue(propValue);
    // eslint-disable-next-line
  }, [propValue]);

  const contextValue = useMemo(() => {
    return isHandleByProps ? propValue : value;
  }, [isHandleByProps, propValue, value]);

  if (isLoading) {
    return <SHTabSkeleton />;
  }

  return (
    <TabContext value={contextValue}>
      <SHStack>
        <SHBox
          sx={{
            borderBottom: 1,
            borderColor: palette.common.black,
            pb: "10px",
          }}
          data-testid="sh-tabs"
        >
          <TabList
            {...tabListProps}
            onChange={handleChange}
            variant="scrollable"
          >
            {tabs.map(
              (
                {
                  component,
                  hidden,
                  linkTo,
                  dotGroupProps,
                  ...tabProps
                }: SHTab,
                index: number,
              ) =>
                hidden ? null : linkTo !== undefined && !tabProps.disabled ? (
                  <TabLink to={linkTo} end={linkTo === ""} key={index}>
                    {({ isActive }) => (
                      <Tab
                        {...tabProps}
                        label={
                          <TabLabel
                            icon={tabProps.icon}
                            label={tabProps.label}
                            disabled={tabProps?.disabled}
                            dotGroupProps={dotGroupProps}
                            isActive={isActive}
                          />
                        }
                        icon={undefined}
                        key={index}
                        value={index.toString()}
                        disableRipple
                        className={isActive ? "Mui-selected" : undefined}
                      />
                    )}
                  </TabLink>
                ) : (
                  <Tab
                    {...tabProps}
                    label={
                      <TabLabel
                        icon={tabProps.icon}
                        label={tabProps.label}
                        disabled={tabProps?.disabled}
                        dotGroupProps={dotGroupProps}
                        isActive={index.toString() === contextValue}
                      />
                    }
                    icon={undefined}
                    key={index}
                    value={index.toString()}
                    disableRipple
                  />
                ),
            )}
          </TabList>
        </SHBox>
        {tabs.map(({ hidden, component }: SHTab, index: number) =>
          hidden ? null : (
            <TabPanel value={index.toString()} key={index} sx={{ p: 0 }}>
              {component}
            </TabPanel>
          ),
        )}
      </SHStack>
    </TabContext>
  );
};
