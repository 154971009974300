import { DataStatusBadge } from "@components/data-status-badge";
import {
  SHImage,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { DateFormat } from "@constants/format";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ProductDTO } from "@models/product/entities/product";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";

export interface ProductCardProps extends SHStackProps {
  product?: ProductDTO;
  onClickProduct: (productId?: string) => void;
}
export const ProductCard = ({
  product,
  onClickProduct,
  ...others
}: ProductCardProps) => {
  const { palette } = useTheme();
  const { canViewProductStatus } = useUserPermissions();

  const cardBoxShadowColor = hexToRGBA(palette.common.black, 0.25);

  return (
    <SHStack
      width={{ lg: "32.75%", md: "49.33%", sm: "49.3%" }}
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        gap: "16px",
        cursor: "pointer",
        padding: "20px 25px",
        backdropFilter: "blur(0.5px)",
        background: palette.common.white,
        border: `1px solid ${palette.secondary[100]}`,
        boxShadow: `3px 3px 4px ${cardBoxShadowColor}`,
        "&:hover": {
          boxShadow: "none",
          border: `1px solid ${palette.primary.dark}`,
        },
        "&:active": {
          boxShadow: `3px 3px 4px ${cardBoxShadowColor} inset`,
          border: `1px solid ${palette.primary.dark}`,
          backgroundColor: palette.secondary[50],
        },
      }}
      onClick={() => onClickProduct(product?.id)}
      {...others}
    >
      <SHStack
        direction="row"
        spacing={1}
        width={311}
        gap={"15px"}
        alignItems={"center"}
      >
        <SHImage src={product?.productLogo} width={67.1} height={67.1} />
        <SHStack textAlign={"start"}>
          <SHTypography
            variant="h2"
            fontSize={20}
            fontWeight={500}
            lineHeight={"120%"}
            sx={{ wordBreak: "break-word" }}
          >
            {product?.productName}
          </SHTypography>
          <SHTypography variant="body4">
            {format(
              product?.lastModifiedDate ?? new Date(),
              `'Most recent update: ' ${DateFormat}`,
            )}
          </SHTypography>
          {canViewProductStatus(product?.id) && (
            <DataStatusBadge status={product?.dataStatus} />
          )}
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
