import { SHStack, SHTypography } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { Link, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";

const UpgradeSubscriptionView = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <SHStack
      display={"flex"}
      gridTemplateColumns={"6.6fr 3.25fr"}
      marginTop={"25px"}
      gap={"15px"}
    >
      <SHTypography variant="subtitle2">
        Please upgrade your subscription
        <Link
          color={palette.text.primary}
          component="a"
          underline="always"
          onClick={() => {
            navigate(
              `${generatePath(PageRoutes.practiceOverview, {
                practiceId: user?.userMetadata?.adviser_firm_id,
              })}/subscription`,
            );
          }}
          sx={{
            paddingX: 0.5,
            cursor: "pointer",
            textDecorationColor: palette.text.primary,
          }}
        >
          here
        </Link>
        to view detailed information about the models and ESG policies for this
        managed account provider
      </SHTypography>
    </SHStack>
  );
};

export default UpgradeSubscriptionView;
