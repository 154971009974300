import {
  SHBox,
  SHButton,
  SHHtmlBlock,
  SHStack,
} from "@components/design-systems";
import { DownloadSVG, TooltipAllowSVG } from "@components/svgs";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { ReviewDTO } from "@models/reviews/entities/review";
import {
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import {
  MAX_LIGHTNESS,
  MIN_LIGHTNESS,
} from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap/config";
import {
  DEFAULT_COLORS,
  DEFAULT_COLOR_TICK,
} from "@pages/platform-analysis/_id/steps/fee/analysis/components/line-chart/config";
import { PDFDisclaimer } from "@pages/platform-analysis/components/buttons/export-pdf/components/disclaimer";
import { PDFFeeLineChart } from "@pages/platform-analysis/components/buttons/export-pdf/components/fee-line-chart";
import { PDFFeeLineChartSubProduct } from "@pages/platform-analysis/components/buttons/export-pdf/components/fee-line-chart-sub-product";
import { PDFFeePortfolioDetailsSection } from "@pages/platform-analysis/components/buttons/export-pdf/components/sections/fee-portfolio-details";
import { PDFTitleSection } from "@pages/platform-analysis/components/buttons/export-pdf/components/title-section";
import {
  APPENDIX_COMMENT_TABLE_HEADER_HEIGHT,
  COMMENT_TABLE_HEADER_HEIGHT,
  DEFAULT_GAP,
  HEIGHT_LEGEND_CHART,
  MIN_HEIGHT_CHART,
  REVIEW_PAGE_BODY_HEIGHT,
  SUB_TITLE_HEIGHT,
  TABLE_HEIGHT_OFFSET,
  TITLE_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PageTemplate } from "@pages/platform-analysis/components/buttons/export-pdf/model";
import { objectivesPageHandler } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { PDFReviewPageContainer } from "@pages/reviews/components/buttons/export-pdf/layouts/pages";
import {
  PDFBusinessReview,
  PDFFeatureReview,
  PDFFeeTableData,
  PDFReviewComment,
  PDFReviewData,
} from "@pages/reviews/components/buttons/export-pdf/model";
import { PDFReviewOverviewSection } from "@pages/reviews/components/buttons/export-pdf/sections/overview";
import {
  dynamicBusinessMetricReview,
  dynamicFeatureIncluded,
  dynamicFeatureReview,
  dynamicFeeEstimatesTable,
  dynamicPortfolioDetails,
  extractReviewComments,
  generatePDFReviewData,
} from "@pages/reviews/components/buttons/export-pdf/util";
import { Document, PDFViewer, View, pdf } from "@react-pdf/renderer";
import { RootState } from "@redux/store";
import { getHSLLightness, hexToRGBA, hslToHex } from "@utils";
import saveAs from "file-saver";
import { chunk, cloneDeep, groupBy, isEmpty, join, map } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PDFBusinessMetricIncludeTable } from "./sections/business-metric-included";
import { PDFBusinessReviewSection } from "./sections/business-review";
import { PDFReviewCommentSection } from "./sections/comments";
import { PDFFeatureReviewSection } from "./sections/feature-review";
import { PDFFeaturesIncludedSection } from "./sections/features-include";
import { PDFFeeReviewSection } from "./sections/fee-review";
import { PDFReviewPlatformSelectedSection } from "./sections/platform-selected";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { DELAY_TIME } from "@constants";

const MARGIN_TOP_DEFAULT = 15;
interface ReviewExportPDFButtonProps {
  isCompleted?: boolean;
}

export const ReviewExportPDFButton = ({
  isCompleted = false,
}: ReviewExportPDFButtonProps) => {
  const { palette, shadows } = useTheme();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const { review, disclaimers, lineChartColors, appendixLineChartColors } =
    useSelector((state: RootState) => state.review);

  useEffect(() => {
    setIsDisable(isDownloading || !isCompleted || !review);
  }, [isDownloading, isCompleted, review]);

  const handleOnDownloadPDF = async () => {
    if (!review) return <></>;
    setIsDownloading(true);
    const blob = await pdf(
      <PDFDocument
        review={review}
        disclaimers={disclaimers}
        lineChartColors={lineChartColors}
        appendixLineChartColors={appendixLineChartColors}
      />,
    ).toBlob();
    saveAs(
      blob,
      `SuitabilityHub Suitability Review - ${review?.summary?.name}.pdf`,
    );
    setIsDownloading(false);
  };

  const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      paddingRight: "0px",
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
      {
        marginTop: "-1px",
      },
  }));

  return (
    <TooltipStyled
      placement="top-end"
      enterDelay={DELAY_TIME}
      enterNextDelay={DELAY_TIME}
      title={
        isDisable && !isDownloading ? (
          <SHStack
            sx={{
              p: 1,
              mt: "3px",
              maxWidth: "190px",
              minHeight: "20px",
              bgcolor: hexToRGBA(palette.common.white, 0.85),
              border: "1px solid #E3E3E3",
              backdropFilter: "blur(2px)",
              borderRadius: "3px",
              boxShadow: shadows[1],
              position: "relative",
            }}
          >
            <SHHtmlBlock
              variant="body3"
              color={palette.text.disabled}
              content={"Complete your review to download it as PDF"}
              textAlign={"left"}
            />
            <SHBox sx={{ position: "absolute", top: "-8px", left: "82%" }}>
              <TooltipAllowSVG transform={"rotate(180)"} />
            </SHBox>
          </SHStack>
        ) : (
          ""
        )
      }
    >
      <SHBox component={"span"}>
        <SHButton
          variant="contained"
          size="extraMedium"
          startIcon={<DownloadSVG />}
          disabled={isDownloading || !isCompleted || !review}
          isLoading={isDownloading}
          onClick={() => handleOnDownloadPDF()}
          sx={{
            "&.Mui-disabled.sh-btn-with-icon": {
              border: `1px solid ${hexToRGBA(palette.primary.dark, 0.3)}`,
              backgroundColor: palette.primary[50],
              color: palette.common.white,
            },
          }}
        >
          PDF
        </SHButton>
      </SHBox>
    </TooltipStyled>
  );
};

const PDFDocument = ({
  review,
  disclaimers,
  lineChartColors,
  appendixLineChartColors,
}: {
  review: ReviewDTO;
  disclaimers?: DisclaimerDTO[];
  lineChartColors?: { [key in string]: string };
  appendixLineChartColors?: { [key in string]: string };
}): JSX.Element => {
  const generatePDFPages = (pdfData: PDFReviewData) => {
    let pages: PageTemplate[] = [];
    let spaceAvailable = REVIEW_PAGE_BODY_HEIGHT;

    const finalShortlisted =
      pdfData?.feeReviewShortlisted?.selectedProducts ?? [];
    const { familyGroupName } = pdfData.overview;

    pages?.push({
      components: [
        <View style={{ gap: DEFAULT_GAP }}>
          <PDFReviewOverviewSection overview={pdfData?.overview} />
        </View>,
      ],
    });

    if (pdfData.objectives) {
      spaceAvailable = objectivesPageHandler(
        pages,
        pdfData.objectives,
        spaceAvailable,
        false,
        true,
      );
    }

    spaceAvailable = platformsSelectedPageHandler(
      pages,
      spaceAvailable,
      finalShortlisted,
      familyGroupName,
    );
    spaceAvailable = commentPagesHandler(
      pages,
      spaceAvailable,
      pdfData?.reviewCommentsShortlisted ?? [],
    );

    spaceAvailable = featureReviewPageHandler(
      pages,
      spaceAvailable,
      pdfData,
      pdfData.displayModes?.featureAnalysisTableViewMode ===
        TableViewMode.Detail,
    );

    spaceAvailable = businessReviewPageHandler(pages, spaceAvailable, pdfData);

    spaceAvailable = portfolioDetailPageHandler(pages, spaceAvailable, pdfData);

    spaceAvailable = feesReviewPageHandler(pdfData, pages, spaceAvailable);

    if (pdfData.controls?.showFeeAnalysisGraph) {
      spaceAvailable = feesReviewLineChartPageHandler(
        pdfData,
        pages,
        spaceAvailable,
        false,
        lineChartColors,
        appendixLineChartColors,
      );
    }

    if (pdfData?.concludingRemarks) {
      objectivesPageHandler(
        pages,
        pdfData?.concludingRemarks,
        spaceAvailable,
        true,
        true,
      );
    }

    return pages;
  };

  const generatePDFAppendixPages = (pdfData: PDFReviewData) => {
    let pages: PageTemplate[] = [];
    let spaceAvailable = REVIEW_PAGE_BODY_HEIGHT;
    spaceAvailable = commentPagesHandler(
      pages,
      REVIEW_PAGE_BODY_HEIGHT,
      pdfData?.reviewCommentsDisqualified ?? [],
      true,
    );

    if (spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2)
      spaceAvailable -= MARGIN_TOP_DEFAULT;

    if (!isEmpty(pdfData?.feeReviewDisqualified?.subProducts)) {
      spaceAvailable = appendixFeesReviewPageHandler(
        pdfData,
        pages,
        spaceAvailable,
      );

      if (pdfData.controls?.showFeeAnalysisGraph) {
        spaceAvailable = feesReviewLineChartPageHandler(
          pdfData,
          pages,
          spaceAvailable,
          true,
          lineChartColors,
          appendixLineChartColors,
        );
      }

      if (spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2)
        spaceAvailable -= MARGIN_TOP_DEFAULT;
    }

    if (pdfData?.controls?.showSelectedFeatures) {
      spaceAvailable = featureIncludedPageHandler(
        pages,
        spaceAvailable,
        pdfData,
      );
      businessIncludedPageHandler(pages, spaceAvailable, pdfData);
    }

    pages?.push({
      components: [
        <PDFDisclaimer
          hasFeeStep={true}
          disclaimers={disclaimers}
          isReview={true}
        />,
      ],
    });

    return pages;
  };

  const pdfData = generatePDFReviewData(review);

  const pages = generatePDFPages(pdfData);

  const appendixPages = generatePDFAppendixPages(pdfData);

  return (
    <Document>
      {pages?.map((page, index) => (
        <PDFReviewPageContainer
          key={index}
          pageIndex={index + 1}
          totalPage={pages?.length + appendixPages?.length}
          overviewData={pdfData?.overview}
          isFirstPage={index === 0}
        >
          {page.components?.map((el) => el)}
        </PDFReviewPageContainer>
      ))}
      {appendixPages?.map((page, index) => (
        <PDFReviewPageContainer
          key={index}
          pageIndex={pages.length + index + 1}
          totalPage={pages?.length + appendixPages?.length}
          overviewData={pdfData?.overview}
          isFirstPage={false}
          isAppendix={true}
        >
          {page.components?.map((el) => el)}
        </PDFReviewPageContainer>
      ))}
    </Document>
  );
};

const pagesHandler = (
  pages: PageTemplate[],
  component: JSX.Element,
  isAppend?: boolean,
) => {
  if (isAppend) {
    const lastPage = pages[pages?.length - 1];
    lastPage?.components?.push(<View style={{ paddingBottom: 15 }}></View>);
    lastPage?.components?.push(component);
  } else {
    pages?.push({
      components: [component],
    });
  }
};

const platformsSelectedPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  finalShortlisted: SelectedProductDTO[],
  familyGroupName?: string,
) => {
  let isAppend =
    spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2 &&
    spaceAvailable !== REVIEW_PAGE_BODY_HEIGHT;

  const platformDisplayed = join(
    map(finalShortlisted, "productName"),
    ", ",
  ).replace(/, (?!.*,)/g, " and ");

  const platformsSelectedCompHeight =
    TITLE_HEIGHT +
    Math.ceil(platformDisplayed?.split(" ")?.length / 16) * 23 +
    (isAppend ? MARGIN_TOP_DEFAULT : 0);

  const platformSelectedSectionComp = (
    <PDFReviewPlatformSelectedSection
      finalShortlisted={finalShortlisted ?? []}
      selectedProductDisplay={platformDisplayed}
      familyGroupName={familyGroupName}
      isAppend={isAppend}
    />
  );

  pagesHandler(pages, platformSelectedSectionComp, isAppend);

  return isAppend
    ? spaceAvailable - platformsSelectedCompHeight
    : REVIEW_PAGE_BODY_HEIGHT - platformsSelectedCompHeight;
};

const commentPagesHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  commentsData: PDFReviewComment[],
  isAppendix?: boolean,
) => {
  const isAppend = spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2 && !isAppendix;

  spaceAvailable =
    (isAppend ? spaceAvailable : REVIEW_PAGE_BODY_HEIGHT) -
    ((isAppendix
      ? APPENDIX_COMMENT_TABLE_HEADER_HEIGHT
      : COMMENT_TABLE_HEADER_HEIGHT) +
      TITLE_HEIGHT) -
    10;

  const comments = extractReviewComments(
    commentsData,
    spaceAvailable,
    isAppendix,
  );
  let breakComments: PDFReviewComment[] = [];
  let isUpdateAvailablePage = false;
  let isExpandTable = false;

  const setPageValues = () => {
    if (isAppend && !isUpdateAvailablePage) {
      pagesHandler(
        pages,
        <PDFReviewCommentSection
          comments={breakComments ?? []}
          isAppendix={isAppendix}
          isExpandTable={isExpandTable}
        />,
        true,
      );
      isUpdateAvailablePage = true;
    } else {
      pagesHandler(
        pages,
        <PDFReviewCommentSection
          comments={breakComments ?? []}
          isExpandTable={isExpandTable}
          isAppendix={isAppendix}
        />,
        false,
      );
    }
  };
  comments?.forEach((comment, index) => {
    if (comment.height < spaceAvailable) {
      breakComments.push(comment);
      spaceAvailable -= comment.height;
    } else {
      setPageValues();
      breakComments = [comment];
      isExpandTable = true;
      spaceAvailable =
        REVIEW_PAGE_BODY_HEIGHT -
        (COMMENT_TABLE_HEADER_HEIGHT + TITLE_HEIGHT) -
        comment.height;
    }
    if (index === comments.length - 1) {
      setPageValues();
    }
  });

  return spaceAvailable - (isAppend ? MARGIN_TOP_DEFAULT : 0);
};

const featureReviewPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  pdfData: any,
  isDetailMode?: boolean,
) => {
  const pageBodyHeight = REVIEW_PAGE_BODY_HEIGHT;
  const isAppend = spaceAvailable > pageBodyHeight / 2;
  spaceAvailable = isAppend
    ? spaceAvailable - MARGIN_TOP_DEFAULT
    : pageBodyHeight;

  let isTableExpand = false;
  let featureReview = cloneDeep(pdfData?.featureReviewShortlisted?.pdfSections);
  let data = dynamicFeatureReview(spaceAvailable, featureReview, isTableExpand);

  let featureReviewComp = <></>;

  featureReviewComp = (
    <PDFFeatureReviewSection
      isTableExpand={isTableExpand}
      featureReview={
        {
          ...pdfData?.featureReviewShortlisted,
          pdfSections: data.dataSeparated,
        } as PDFFeatureReview
      }
      showSubSection={pdfData?.controls?.showSubSection}
      isAppend={isAppend}
      isDetailMode={isDetailMode}
    />
  );

  pagesHandler(pages, featureReviewComp, isAppend);

  isTableExpand = true;

  while (data.hasContinue === true) {
    spaceAvailable = REVIEW_PAGE_BODY_HEIGHT;
    data = dynamicFeatureReview(spaceAvailable, featureReview, true);

    featureReviewComp = (
      <PDFFeatureReviewSection
        isTableExpand={isTableExpand}
        featureReview={
          {
            ...pdfData?.featureReviewShortlisted,
            pdfSections: data.dataSeparated,
          } as PDFFeatureReview
        }
        showSubSection={pdfData?.controls?.showSubSection}
        isDetailMode={isDetailMode}
      />
    );
    pagesHandler(pages, featureReviewComp, false);
  }

  return spaceAvailable - data.occupyHeight - TABLE_HEIGHT_OFFSET;
};

const businessReviewPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  pdfData: PDFReviewData,
) => {
  const isAppend = spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2;
  spaceAvailable = isAppend ? spaceAvailable : REVIEW_PAGE_BODY_HEIGHT;
  let isTableExpand = false;
  let businessReview = cloneDeep(
    pdfData?.businessReviewShortlisted?.pdfSections,
  );

  let data;
  let featureReviewComp = <></>;

  data = dynamicBusinessMetricReview(
    spaceAvailable,
    businessReview ?? [],
    isTableExpand,
    isAppend,
  );

  featureReviewComp = (
    <PDFBusinessReviewSection
      isTableExpand={isTableExpand}
      businessReview={
        {
          ...pdfData?.businessReviewShortlisted,
          pdfSections: data.dataSeparated,
        } as PDFBusinessReview
      }
    />
  );

  pagesHandler(pages, featureReviewComp, isAppend);
  isTableExpand = true;

  while (data.hasContinue === true) {
    spaceAvailable = REVIEW_PAGE_BODY_HEIGHT;
    data = dynamicBusinessMetricReview(
      spaceAvailable,
      businessReview ?? [],
      isTableExpand,
      false,
    );
    featureReviewComp = (
      <PDFBusinessReviewSection
        isTableExpand={isTableExpand}
        businessReview={
          {
            ...pdfData?.businessReviewShortlisted,
            pdfSections: data.dataSeparated,
          } as PDFBusinessReview
        }
      />
    );
    pagesHandler(pages, featureReviewComp, false);
  }

  return spaceAvailable - data.occupyHeight;
};

const portfolioDetailPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  pdfData: PDFReviewData,
) => {
  let data = dynamicPortfolioDetails(
    spaceAvailable,
    pdfData.feePortfolioDetails,
    pdfData?.controls?.showHoldingNumber,
  );

  const portfolioDetailsComp = (
    <PDFFeePortfolioDetailsSection
      data={pdfData.feePortfolioDetails}
      isShowHoldingsNumber={pdfData?.controls?.showHoldingNumber}
      isSuitabilityReview
    />
  );

  if (data.occupyHeight) {
    pagesHandler(pages, portfolioDetailsComp, true);
    return spaceAvailable - data.occupyHeight;
  } else {
    data = dynamicPortfolioDetails(
      REVIEW_PAGE_BODY_HEIGHT,
      pdfData.feePortfolioDetails,
      pdfData?.controls?.showHoldingNumber,
    );
    pagesHandler(pages, portfolioDetailsComp, false);
    return REVIEW_PAGE_BODY_HEIGHT - data.occupyHeight;
  }
};

const heightFitWithSpaceAvailable = (
  pages: PageTemplate[],
  spaceAvailable: number,
  dataTable: PDFFeeTableData,
  isAppendix: boolean,
  heatmapMode: FeeHeatmapMode,
) => {
  let subProductData = cloneDeep(dataTable.subProduct);
  const projectionMode = heatmapMode === FeeHeatmapMode.Projection;
  let data = dynamicFeeEstimatesTable(
    spaceAvailable,
    subProductData ?? [],
    true,
    projectionMode,
  );

  const isShowTitle = projectionMode ? false : isAppendix ? true : false;

  if (data.hasContinue === false && data.occupyHeight) {
    let feeEstimatesComp = (
      <PDFFeeReviewSection
        numberSubProductHidden={dataTable.numberSubProductHidden}
        subProducts={data.dataSeparated ?? []}
        totalPortfolioValue={dataTable.totalPortfolioValue}
        mode={dataTable.feesDisplayStyle as any}
        heatmapMode={heatmapMode}
        showWarningCaptions={dataTable.showWarningCaptions}
        hiddenIds={dataTable.hiddenIds}
        isShowTitle={isShowTitle}
        isShowSubTitle={!projectionMode}
        isAppendix={isAppendix}
      />
    );
    pagesHandler(pages, feeEstimatesComp, true);

    return data.occupyHeight;
  }
  return -1;
};

const feeEstimatesDetailTablePageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  dataTable: PDFFeeTableData,
  isAppendix: boolean,
) => {
  let heightFit = heightFitWithSpaceAvailable(
    pages,
    spaceAvailable,
    dataTable,
    isAppendix,
    FeeHeatmapMode.Detail,
  );
  if (heightFit !== -1) return spaceAvailable - heightFit;

  const isAppend = spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2;
  spaceAvailable = isAppend ? spaceAvailable : REVIEW_PAGE_BODY_HEIGHT;
  let subProductData = cloneDeep(dataTable.subProduct);
  let data = dynamicFeeEstimatesTable(
    spaceAvailable,
    subProductData ?? [],
    isAppendix ? isAppend : false,
    false,
  );
  let feeEstimatesComp = <></>;
  feeEstimatesComp = (
    <PDFFeeReviewSection
      numberSubProductHidden={dataTable.numberSubProductHidden}
      subProducts={data.dataSeparated ?? []}
      totalPortfolioValue={dataTable.totalPortfolioValue}
      mode={dataTable.feesDisplayStyle as any}
      heatmapMode={FeeHeatmapMode.Detail}
      showWarningCaptions={dataTable.showWarningCaptions}
      hiddenIds={dataTable.hiddenIds}
      isShowTitle={isAppendix ? true : !isAppend}
      isShowSubTitle={true}
      isAppendix={isAppendix}
    />
  );
  pagesHandler(pages, feeEstimatesComp, isAppend);

  while (data.hasContinue === true) {
    spaceAvailable = REVIEW_PAGE_BODY_HEIGHT;
    data = dynamicFeeEstimatesTable(spaceAvailable, subProductData ?? []);

    feeEstimatesComp = (
      <PDFFeeReviewSection
        numberSubProductHidden={dataTable.numberSubProductHidden}
        subProducts={data.dataSeparated ?? []}
        totalPortfolioValue={dataTable.totalPortfolioValue}
        mode={dataTable.feesDisplayStyle as any}
        heatmapMode={FeeHeatmapMode.Detail}
        showWarningCaptions={dataTable.showWarningCaptions}
        hiddenIds={dataTable.hiddenIds}
        isShowTitle={true}
        isShowSubTitle={false}
      />
    );
    pagesHandler(pages, feeEstimatesComp, false);
  }

  return spaceAvailable - data.occupyHeight;
};

const feeEstimatesProjectionTablePageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  dataTable: PDFFeeTableData,
) => {
  let heightFit = heightFitWithSpaceAvailable(
    pages,
    spaceAvailable,
    dataTable,
    false,
    FeeHeatmapMode.Projection,
  );

  if (heightFit !== -1) return spaceAvailable - heightFit;
  const isAppend = spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2;
  let availableHeight = isAppend
    ? spaceAvailable - 20
    : REVIEW_PAGE_BODY_HEIGHT;
  let subProductData = cloneDeep(dataTable.subProduct);
  let data = dynamicFeeEstimatesTable(
    availableHeight,
    subProductData ?? [],
    isAppend,
    true,
  );
  let feeEstimatesComp = <></>;

  feeEstimatesComp = (
    <PDFFeeReviewSection
      numberSubProductHidden={dataTable.numberSubProductHidden}
      subProducts={data.dataSeparated ?? []}
      totalPortfolioValue={dataTable.totalPortfolioValue}
      mode={dataTable.feesDisplayStyle as any}
      heatmapMode={FeeHeatmapMode.Projection}
      showWarningCaptions={dataTable.showWarningCaptions}
      hiddenIds={dataTable.hiddenIds}
      isShowTitle={!isAppend}
      isShowSubTitle={false}
    />
  );
  pagesHandler(pages, feeEstimatesComp, isAppend);

  while (data.hasContinue === true) {
    availableHeight = REVIEW_PAGE_BODY_HEIGHT;
    data = dynamicFeeEstimatesTable(
      availableHeight,
      subProductData ?? [],
      false,
    );
    feeEstimatesComp = (
      <PDFFeeReviewSection
        numberSubProductHidden={dataTable.numberSubProductHidden}
        subProducts={data.dataSeparated ?? []}
        totalPortfolioValue={dataTable.totalPortfolioValue}
        mode={dataTable.feesDisplayStyle as any}
        heatmapMode={FeeHeatmapMode.Projection}
        showWarningCaptions={dataTable.showWarningCaptions}
        hiddenIds={dataTable.hiddenIds}
        isShowTitle={true}
        isShowSubTitle={false}
      />
    );
    pagesHandler(pages, feeEstimatesComp, false);
  }

  return availableHeight - data.occupyHeight;
};

export const feesReviewLineChartPageHandler = (
  pdfData: PDFReviewData,
  pages: PageTemplate[],
  spaceAvailable: number,
  isAppendix: boolean,
  lineChartColors?: { [key in string]: string },
  appendixLineChartColors?: { [key in string]: string },
) => {
  let feeSubProducts: FeeSubProductDTO[] = [];
  const feesDisplayStyle: FeesDisplayStyle =
    pdfData.displayModes?.feesDisplayMode ?? FeesDisplayStyle.Dollar;

  const subProducts = isAppendix
    ? pdfData.feeReviewDisqualified?.subProducts
    : pdfData.feeReviewShortlisted?.subProducts;
  const hiddenSubProductIds = isAppendix
    ? pdfData?.appendixHiddenSubProductIds
    : pdfData?.hiddenSubProductIds;

  const feeMissingInvestmentsStyle = isAppendix
    ? pdfData?.displayModes?.appendixFeeMissingInvestmentsDisplayMode ??
      FeeMissingInvestmentsOption?.AllPlatform
    : pdfData?.displayModes?.feeMissingInvestmentsDisplayMode ??
      FeeMissingInvestmentsOption?.AllPlatform;

  const feeInvestmentMenuStyle = isAppendix
    ? pdfData.displayModes?.appendixInvestmentMenu ??
      FeeInvestmentMenuOption.AllPlatform
    : pdfData.displayModes?.investmentMenu ??
      FeeInvestmentMenuOption.AllPlatform;

  let subProductsFiltered = cloneDeep(subProducts);

  if (feeInvestmentMenuStyle !== FeeInvestmentMenuOption.AllPlatform) {
    subProductsFiltered = subProductsFiltered?.filter(
      (subProduct) => subProduct.investmentMenu === feeInvestmentMenuStyle,
    );
  }

  if (feeMissingInvestmentsStyle !== FeeMissingInvestmentsOption.AllPlatform) {
    subProductsFiltered = subProductsFiltered?.filter((subProduct) =>
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.Missing
        ? subProduct.warning
        : !subProduct.warning,
    );
  }

  if (!isEmpty(hiddenSubProductIds)) {
    const subProductsHidden = subProductsFiltered?.filter(
      (subProduct) => !hiddenSubProductIds?.includes(subProduct.id),
    );
    feeSubProducts = subProductsHidden ?? [];
  } else {
    feeSubProducts = subProductsFiltered ?? [];
  }

  let fullGraphLegendHeight = 0;

  chunk(feeSubProducts, 5).forEach((items) => {
    let maxLength = 0;
    items.forEach((item) => {
      maxLength = item.name.length > maxLength ? item.name.length : maxLength;
    });
    const MAX_TEXT = 24;
    const countExtraLine = Math.floor(maxLength / MAX_TEXT);

    fullGraphLegendHeight +=
      HEIGHT_LEGEND_CHART + countExtraLine * (HEIGHT_LEGEND_CHART / 2);
  });

  const occupyHeight = MIN_HEIGHT_CHART + fullGraphLegendHeight;
  const isAppend = spaceAvailable >= occupyHeight;

  const getColorsLineChart = (): Record<string, string> => {
    const groups = groupBy(subProducts, (obj) => obj.productId);
    const colors: Record<string, string> = {};
    Object.keys(groups).forEach((key, index) => {
      const group = groups[key];
      const colorRoot = DEFAULT_COLORS[index];
      if (group.length === 1) {
        colors[group[0].id] = hslToHex(
          colorRoot.hue,
          colorRoot.saturation,
          colorRoot.lightness,
        );
      } else {
        const tickColor = (MAX_LIGHTNESS - colorRoot.lightness) / group.length;
        const minLightness =
          tickColor >= DEFAULT_COLOR_TICK
            ? colorRoot.lightness
            : Math.max(
                MAX_LIGHTNESS - DEFAULT_COLOR_TICK * group.length,
                MIN_LIGHTNESS,
              );

        let maxLightness = Math.min(
          colorRoot.lightness + DEFAULT_COLOR_TICK * 1.5,
          MAX_LIGHTNESS,
        );

        if (group.length > 5) {
          maxLightness = Math.min(
            colorRoot.lightness + DEFAULT_COLOR_TICK * (group.length - 1),
            MAX_LIGHTNESS,
          );
        }

        group.forEach((item, itemIndex) => {
          const lightness = getHSLLightness(
            1,
            group.length,
            itemIndex + 1,
            minLightness,
            maxLightness,
          );
          colors[item.id] = hslToHex(
            colorRoot.hue,
            colorRoot.saturation,
            lightness,
          );
        });
      }
    });
    return colors;
  };

  const colors = lineChartColors || getColorsLineChart();

  if (!feeSubProducts.length) return spaceAvailable;

  pagesHandler(
    pages,
    <View
      style={{
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <PDFFeeLineChart
        feeSubProducts={feeSubProducts}
        chartMode={feesDisplayStyle}
        colors={colors}
        isAppend={isAppend}
        title="Fee review (continued)"
      />
      {chunk(feeSubProducts, 5).map((items) => (
        <View
          style={{
            textAlign: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <PDFFeeLineChartSubProduct subProducts={items} colors={colors} />
        </View>
      ))}
    </View>,
    isAppend,
  );

  if (isAppend) {
    return spaceAvailable - occupyHeight;
  }

  return (
    REVIEW_PAGE_BODY_HEIGHT - occupyHeight - TITLE_HEIGHT - SUB_TITLE_HEIGHT
  );
};

const featureIncludedPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  pdfData: PDFReviewData,
) => {
  const isAppend = spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2;
  let availableHeight = isAppend
    ? spaceAvailable - 40
    : REVIEW_PAGE_BODY_HEIGHT;
  let featureIncluded = cloneDeep(pdfData?.featureIncluded);
  let data = dynamicFeatureIncluded(availableHeight, featureIncluded ?? []);

  let featureIncludedComp = <></>;

  featureIncludedComp = (
    <View>
      <PDFTitleSection title={`Features included in the review`} />
      <PDFFeaturesIncludedSection featureInclude={data.dataSeparated} />
    </View>
  );
  pagesHandler(pages, featureIncludedComp, isAppend);

  while (data.hasContinue === true) {
    availableHeight = REVIEW_PAGE_BODY_HEIGHT;
    data = dynamicFeatureIncluded(availableHeight, featureIncluded ?? []);
    featureIncludedComp = (
      <View>
        <PDFTitleSection
          title={`Features included in the review (continued)`}
        />
        <PDFFeaturesIncludedSection featureInclude={data.dataSeparated} />
      </View>
    );
    pagesHandler(pages, featureIncludedComp, false);
  }

  return availableHeight - data.occupyHeight;
};

const businessIncludedPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  pdfData: PDFReviewData,
) => {
  const isAppend = spaceAvailable > REVIEW_PAGE_BODY_HEIGHT / 2;
  let availableHeight = isAppend ? spaceAvailable : REVIEW_PAGE_BODY_HEIGHT;
  let businessIncluded = cloneDeep(pdfData?.businessIncluded);
  let data = dynamicFeatureIncluded(availableHeight, businessIncluded ?? []);

  let businessIncludedComp = <></>;

  businessIncludedComp = (
    <View style={{ gap: DEFAULT_GAP }}>
      <View>
        <PDFTitleSection title={`Business metrics included in the review`} />
        <PDFBusinessMetricIncludeTable featureInclude={data.dataSeparated} />
      </View>
    </View>
  );
  pagesHandler(pages, businessIncludedComp, isAppend);

  while (data.hasContinue === true) {
    availableHeight = REVIEW_PAGE_BODY_HEIGHT;
    data = dynamicFeatureIncluded(availableHeight, businessIncluded ?? []);
    businessIncludedComp = (
      <View style={{ gap: DEFAULT_GAP }}>
        <View>
          <PDFTitleSection
            title={`Business metrics included in the review (continued)`}
          />
          <PDFBusinessMetricIncludeTable featureInclude={data.dataSeparated} />
        </View>
      </View>
    );
    pagesHandler(pages, businessIncludedComp, false);
  }

  return availableHeight - data.occupyHeight;
};

const feesReviewPageHandler = (
  pdfData: PDFReviewData,
  pages: PageTemplate[],
  spaceAvailable: number,
) => {
  const feesDisplayStyle =
    pdfData.displayModes?.feesDisplayMode ?? FeesDisplayStyle.Dollar;
  const feeMissingInvestmentsStyle =
    pdfData?.displayModes?.feeMissingInvestmentsDisplayMode ??
    FeeMissingInvestmentsOption?.AllPlatform;
  const feeInvestmentMenuStyle =
    pdfData.displayModes?.investmentMenu ?? FeeInvestmentMenuOption.AllPlatform;

  let feesSubProductsFiltered = cloneDeep(
    pdfData?.feeReviewShortlisted?.subProducts,
  );

  if (feeInvestmentMenuStyle !== FeeInvestmentMenuOption.AllPlatform) {
    feesSubProductsFiltered = feesSubProductsFiltered?.filter(
      (subProduct) => subProduct.investmentMenu === feeInvestmentMenuStyle,
    );
  }

  if (feeMissingInvestmentsStyle !== FeeMissingInvestmentsOption.AllPlatform) {
    feesSubProductsFiltered = feesSubProductsFiltered?.filter((subProduct) =>
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.Missing
        ? subProduct.warning
        : !subProduct.warning,
    );
  }

  const hiddenSubProductIds = pdfData?.hiddenSubProductIds;
  if (!isEmpty(hiddenSubProductIds)) {
    feesSubProductsFiltered = feesSubProductsFiltered?.filter(
      (subProduct) => !hiddenSubProductIds?.includes(subProduct.id),
    );
  }

  let numberSubProductHidden =
    pdfData?.feeReviewShortlisted?.subProducts && feesSubProductsFiltered
      ? pdfData?.feeReviewShortlisted?.subProducts.length -
        feesSubProductsFiltered.length
      : 0;

  let feesDetailViewSubProductsFiltered = cloneDeep(feesSubProductsFiltered);
  let feesProjectionViewSubProductsFiltered = cloneDeep(
    feesSubProductsFiltered,
  );

  const showMissingInvestmentFilter = feesDetailViewSubProductsFiltered?.some(
    (subProduct: FeeSubProductDTO) => subProduct.warning,
  );

  const feeTableData: PDFFeeTableData = {
    subProduct: feesDetailViewSubProductsFiltered ?? [],
    numberSubProductHidden: numberSubProductHidden,
    feesDisplayStyle: feesDisplayStyle,
    hiddenIds: hiddenSubProductIds,
    totalPortfolioValue: pdfData?.feePortfolioDetails?.totalPortfolioValue,
    showWarningCaptions: showMissingInvestmentFilter,
  };

  spaceAvailable = feeEstimatesDetailTablePageHandler(
    pages,
    spaceAvailable,
    feeTableData,
    false,
  );

  spaceAvailable = feeEstimatesProjectionTablePageHandler(
    pages,
    spaceAvailable,
    {
      ...feeTableData,
      subProduct: feesProjectionViewSubProductsFiltered ?? [],
    },
  );

  return spaceAvailable;
};

const appendixFeesReviewPageHandler = (
  pdfData: PDFReviewData,
  pages: PageTemplate[],
  spaceAvailable: number,
) => {
  const feesDisplayStyle =
    pdfData.displayModes?.feesDisplayMode ?? FeesDisplayStyle.Dollar;
  const feeMissingInvestmentsStyle =
    pdfData?.displayModes?.appendixFeeMissingInvestmentsDisplayMode ??
    FeeMissingInvestmentsOption?.AllPlatform;

  const feeInvestmentMenuStyle =
    pdfData.displayModes?.appendixInvestmentMenu ??
    FeeInvestmentMenuOption.AllPlatform;

  let feesSubProductsFiltered = cloneDeep(
    pdfData?.feeReviewDisqualified?.subProducts,
  );

  if (feeInvestmentMenuStyle !== FeeInvestmentMenuOption.AllPlatform) {
    feesSubProductsFiltered = feesSubProductsFiltered?.filter(
      (subProduct) => subProduct.investmentMenu === feeInvestmentMenuStyle,
    );
  }

  if (feeMissingInvestmentsStyle !== FeeMissingInvestmentsOption.AllPlatform) {
    feesSubProductsFiltered = feesSubProductsFiltered?.filter((subProduct) =>
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.Missing
        ? subProduct.warning
        : !subProduct.warning,
    );
  }

  const hiddenSubProductIds = pdfData?.appendixHiddenSubProductIds;
  if (!isEmpty(hiddenSubProductIds)) {
    feesSubProductsFiltered = feesSubProductsFiltered?.filter(
      (subProduct) => !hiddenSubProductIds?.includes(subProduct.id),
    );
  }

  let numberSubProductHidden =
    pdfData?.feeReviewDisqualified?.subProducts && feesSubProductsFiltered
      ? pdfData?.feeReviewDisqualified?.subProducts.length -
        feesSubProductsFiltered.length
      : 0;

  let feesDetailViewSubProductsFiltered = cloneDeep(feesSubProductsFiltered);
  let feesProjectionViewSubProductsFiltered = cloneDeep(
    feesSubProductsFiltered,
  );

  const showMissingInvestmentFilter = feesDetailViewSubProductsFiltered?.some(
    (subProduct: FeeSubProductDTO) => subProduct.warning,
  );

  const feeTableData: PDFFeeTableData = {
    subProduct: feesDetailViewSubProductsFiltered ?? [],
    numberSubProductHidden: numberSubProductHidden,
    feesDisplayStyle: feesDisplayStyle,
    hiddenIds: hiddenSubProductIds,
    totalPortfolioValue: pdfData?.feePortfolioDetails?.totalPortfolioValue,
    showWarningCaptions: showMissingInvestmentFilter,
  };

  spaceAvailable = feeEstimatesDetailTablePageHandler(
    pages,
    spaceAvailable,
    feeTableData,
    true,
  );

  spaceAvailable = feeEstimatesProjectionTablePageHandler(
    pages,
    spaceAvailable,
    {
      ...feeTableData,
      subProduct: feesProjectionViewSubProductsFiltered ?? [],
    },
  );

  return spaceAvailable;
};
// Note: For development and testing
export const ExportPDFPreview = ({
  review,
  disclaimers,
}: {
  review: ReviewDTO;
  disclaimers?: DisclaimerDTO[];
}): JSX.Element => {
  return (
    <SHStack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      width={"100%"}
    >
      <PDFViewer width={"100%"} height={950}>
        <PDFDocument review={review} disclaimers={disclaimers} />
      </PDFViewer>
    </SHStack>
  );
};
