import {
  SHContainer,
  SHStack,
  SHTabs,
  SHTypography,
} from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SupplierUsersGroup } from "@models/users/group";
import { ProductBeingKickedOutDialog } from "@pages/platform-profiles/components/dialog-being-kick-out";
import { ConfirmKickOutDialog } from "@pages/platform-profiles/components/dialog-confirm-kick-out";
import { ConfirmKickOutAdminDialog } from "@pages/platform-profiles/components/dialog-kick-out-admin";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Banner } from "./components/banner";
import { ViewManagerTab } from "./manager";
import { ViewModelsTab } from "./models";
import { ViewSeriesTab } from "./series";
import { ViewAvailabilityTab } from "./availability";
import { ViewESGTab } from "./esg";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@redux/store";
import { FindBDMTab } from "./find-bdm-tab";
import { updateInvestmentProductManagerData, updateInvestmentProductSeriesData, updateInvestmentProductModelsData, updateSelectedTab } from "@redux/slices/product/investment-product";
import { UserType } from "@models/auth";
import { InvestmentProductGroup } from "@models/product/investment-product/enums/investmentProductGroup";

interface ViewInvestmentProductProps { }

export const ViewInvestmentProduct = (
  props: ViewInvestmentProductProps,
) => {
  const { supplierId } = useParams();
  const { investmentProductId } = useParams();
  const {
    investmentProductViewTabs,
    investmentProductUI: { selectedTab },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const isOtherSupplierUser = useMemo(() => {
    if (!user?.userType) return;
    return (
      SupplierUsersGroup.includes(user?.userType) &&
      user?.userMetadata?.supplier_id !== supplierId
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId]);

  const renderTabs = () => {
    if (!investmentProductViewTabs?.length) return [];
    return [
      ...investmentProductViewTabs.map((group) => {
        let component: JSX.Element = <></>;
        switch (group?.name) {
          case "Manager":
            component =  <ViewManagerTab />;
            break;
          case "Series":
            component =  <ViewSeriesTab />;
            break;
          case "Models":
            component =  <ViewModelsTab />;
            break;
          case "Availability":
            component =  <ViewAvailabilityTab />;
            break;
          case "ESG":
            component =  <ViewESGTab />;
            break;
        }
        return {
          label: group.name,
          component: component
        }
      }),
      {
        label: "Find BDM",
        component: <FindBDMTab />,
      },
    ];
  };

  const group = investmentProductViewTabs[selectedTab];

  useEffect(() => {
    if ((user?.userType === UserType.AdviserTrial ||
      user?.userType === UserType.AdviserAdminTrial)) {
      if (group.name === InvestmentProductGroup.Managed ||
        group.name === InvestmentProductGroup.Series ||
        group.name === InvestmentProductGroup.ESG ||
        group.name === InvestmentProductGroup.Models) {
        dispatch(updateInvestmentProductManagerData(undefined));
        dispatch(updateInvestmentProductSeriesData(undefined));
        dispatch(updateInvestmentProductModelsData(undefined));
        return;
      }
    }

    // eslint-disable-next-line
  }, [investmentProductId]);

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack gap={"25px"}>
        <SHStack alignItems={"center"} maxHeight={400}>
          <Banner isOtherSupplierUser={isOtherSupplierUser} />
        </SHStack>
        <SHTypography variant="h2" fontSize={"24px"} fontWeight={600}>
          {!investmentProductViewTabs ? (
            <SHSkeleton height={38} width={"30%"} />
          ) : (
            "Managed account details"
          )}
        </SHTypography>
        <SHTabs
          tabs={renderTabs()}
          onValueChange={(value: string) => {
            dispatch(updateSelectedTab(+value));
          }}
        />
        <ConfirmKickOutAdminDialog
          supplierId={supplierId}
          productId={investmentProductId}
        />
        <ConfirmKickOutDialog supplierId={supplierId} productId={investmentProductId} />
        <ProductBeingKickedOutDialog />
      </SHStack>
    </SHContainer>
  );
};
