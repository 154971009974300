import { SHStack, SHTypography } from "@components/design-systems";
import { ImageGalleryDialog } from "@components/image-gallery-dialog";
import {
  ImageGalleryItem,
  ResponsiveStyleValue,
} from "@components/image-gallery/components/image-gallery-item";
import { S3ObjectDTO } from "@models/core";
import { useMediaQuery, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { chunk, isEmpty } from "lodash";
import { useState } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import Carousel from "react-material-ui-carousel";

export interface ImageGalleryBoxProps {
  title?: string;
  width?: number | string;
  height?: number | string;
  chunkSize?: number;
  containerHeight?: number | null;
  imageHeight?: number | string;
  imageWidthResponsive?: ResponsiveStyleValue;
  initialImages: S3ObjectDTO[];
  handleOnDelete?: (imageId: string) => void;
}

export const ImageGalleryBox = ({
  title,
  width = 747,
  height,
  containerHeight = null,
  imageHeight,
  imageWidthResponsive = {
    lg: "32.5%",
    md: "49%",
    sm: "48.4%",
  },
  chunkSize = 6,
  initialImages,
  handleOnDelete,
}: ImageGalleryBoxProps) => {
  const { palette } = useTheme();
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [startPreviewImageIndex, setStartPreviewImageIndex] = useState(-1);
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("lg"));
  chunkSize = isSmallScreen ? 4 : chunkSize;
  const previewImages: ReactImageGalleryItem[] = initialImages?.map(
    (item: S3ObjectDTO) => {
      return {
        original: `${item.url}`,
      };
    },
  ) as ReactImageGalleryItem[];

  if (isEmpty(initialImages))
    return (
      <SHStack
        alignItems="center"
        justifyContent={"center"}
        sx={{
          width: width,
          padding: "10px 10px",
          backdropFilter: "blur(0.5px)",
          background: `${hexToRGBA("#c5ddfd", 0.1)}`,
          border: `1px solid ${palette.secondary[100]}`,
        }}
      >
        <SHTypography variant="body4">No images.</SHTypography>
      </SHStack>
    );

  if (initialImages.length > 3) {
    height = imageHeight ? (imageHeight as number) * 2 + 26 : 452;
  } else {
    if (!height) {
      height = imageHeight ? (imageHeight as number) + 26 : 226;
    }
  }

  return (
    <SHStack>
      <Carousel
        autoPlay={false}
        height={height}
        navButtonsAlwaysInvisible
        indicatorIconButtonProps={{
          style: {
            width: 10,
            height: 10,
            boxSizing: "border-box",
          },
        }}
        indicatorContainerProps={{
          style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            padding: "0px",
            marginTop: "10px",
          },
        }}
        sx={{
          width: width,
          padding: "10px 12px",
          backdropFilter: "blur(0.5px)",
          background: `${hexToRGBA("#c5ddfd", 0.1)}`,
          border: `1px solid ${palette.secondary[100]}`,
          overflow: "hidden",
        }}
      >
        {chunk(initialImages, chunkSize)?.map((imageItems) => (
          <ImageGalleryItem
            key={imageItems[0].key}
            title={title}
            height={imageHeight}
            widthResponsive={imageWidthResponsive}
            containerHeight={containerHeight}
            imageItems={imageItems}
            initialImages={initialImages}
            handleOnDelete={handleOnDelete}
            handleOnZoomOut={(imageId) => {
              setShowPreviewImage(true);
              setStartPreviewImageIndex(
                initialImages?.findIndex((el) => el.key === imageId),
              );
            }}
          />
        ))}
      </Carousel>
      <ImageGalleryDialog
        open={showPreviewImage}
        title={title}
        items={previewImages}
        startIndex={startPreviewImageIndex}
        onClose={() => setShowPreviewImage(false)}
      />
    </SHStack>
  );
};
