import { SHBox, SHGrid, SHTypography } from "@components/design-systems";
import { colorLevels } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/configs";

interface GaugeChartProps {
  highlightedPriority?: number;
}

export const GaugeChart = ({ highlightedPriority }: GaugeChartProps) => {
  return (
    <SHGrid container alignItems="stretch" width={"100%"}>
      {colorLevels.map((level) => (
        <SHGrid xs={1.71} key={level.value}>
          <SHBox
            paddingY={0.6}
            sx={{
              backgroundColor:
                level.value === highlightedPriority ? level.color : "#FFFFFF",
              textAlign: "center",
              borderRadius: 0.75,
              boxShadow:
                "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
              transition: "transform 0.1s ease",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <SHTypography
              lineHeight={1}
              color={
                highlightedPriority === level.value
                  ? level.fontColor
                  : undefined
              }
              variant="h6"
              fontWeight="bold"
            >
              {level.value}
            </SHTypography>
            <SHTypography
              color={
                highlightedPriority === level.value
                  ? level.fontColor
                  : undefined
              }
              fontSize={12}
              lineHeight={1}
              variant="body2"
            >
              {level.label}
            </SHTypography>
          </SHBox>
        </SHGrid>
      ))}
    </SHGrid>
  );
};
