export enum SubHeader {
  Model = "Model",
  Platform = "Platform",
  AnnualTotal = "column0",
  PDS = "column1",
  TotalManagementFeesAndCosts = "column2",
  IMFee = "column3",
  UnderlyingManagementFeesAndCosts = "column4",
  CashFee = "column5",
  IntAdminFee = "column6",
  NetOfRebates = "column7",
  PlatformDuc = "column8",
  TotalPerformanceFees = "column9",
  ModelManager = "column10",
  UnderlyingPerformanceFees = "column11",
  TotalTransactionCosts = "column12",
  UnderlyingTransactionCosts = "column13",
  BuySellSpreads = "column14",
  PlatformPercent = "column15",
  PlatformDollar = "column16",
  Netting = "column17",
  ModelTurnover = "column18",
  Discount = "column19",
  Type = "column20",
  FeeMethod = "column21",
  Incepted = "column22",
  MinInvestment = "column23",
  PercentCash = "column24",
  PercentIntListed = "column25",
  Super = "column26",
  IDPS = "column27",
  PrivateLabel = "column28",
  Available = "column29",
  CustomModel = "column30",
}

export enum AvailabilitySubHeader {
  Model = "Model",
  Platform = "Platform",
  PDS = "column0",
  AnnualTotal = "column1",
  IMFee = "column2",
  MinInvestment = "column3",
  PlatformCode = "PlatformCode",
}
