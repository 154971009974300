export class RecordType<T> {
  id?: T;
  description?: string = "";
  name?: string = "";
}

export class ChartType<T> {
  id?: T;
  name?: string;
  isDefault?: boolean;
}
