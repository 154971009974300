import {
  SMAListCurrentlySelectedFilter,
  SMAListFavouriteFilter,
  SMAListMinimumInvestmentSizeFilter,
} from "@models/managed-accounts/enums/filter";
import { SMAListFilter } from "./components/buttons/filters";
import {
  CalculatedAvailabilityInvestmentDto,
  CalculatedInvestmentDTO,
} from "./components/tables/model";
import { filter, isEmpty, some } from "lodash";

export const applySMAAvailabilityListFilters = (
    filters: SMAListFilter,
    currentSelectedIds: string[],
    initialInvestmentData: CalculatedAvailabilityInvestmentDto[],
) => {
  let result = initialInvestmentData;

  const applyFilters = [
    {
      condition: filters.favourites === SMAListFavouriteFilter.Favourites,
      filterFn: (data: CalculatedAvailabilityInvestmentDto[]) =>
          filter(data, { isFavourite: true }),
    },
    {
      condition:
          filters.currentlySelected === SMAListCurrentlySelectedFilter.Selected,
      filterFn: (data: CalculatedAvailabilityInvestmentDto[]) =>
          filter(data, (r) => currentSelectedIds.includes(r.id)),
    },
    {
      condition:
          filters.minimumInvestmentSize !==
          SMAListMinimumInvestmentSizeFilter.All,
      filterFn: (data: CalculatedAvailabilityInvestmentDto[]) =>
          filter(
              data,
              (r) =>
                  Number(r.column3.value) <=
                  {
                    [SMAListMinimumInvestmentSizeFilter.All]: 5000000,
                    [SMAListMinimumInvestmentSizeFilter.OneHundredThousandOrLess]: 100000,
                    [SMAListMinimumInvestmentSizeFilter.FiftyThousandOrLess]: 50000,
                    [SMAListMinimumInvestmentSizeFilter.TwentyFiveThousandOrLess]: 25000,
                    [SMAListMinimumInvestmentSizeFilter.Zero]: 0,
                  }[filters.minimumInvestmentSize],
          ),
    },
    {
      condition: !isEmpty(filters.platforms),
      filterFn: (data: CalculatedAvailabilityInvestmentDto[]) =>
          filter(data, (r) =>
              some(filters.platforms, {
                productName: r.productName,
                subProductName: r.subProductName,
              }),
          ),
    },
    {
      condition: isEmpty(filters.platforms),
      filterFn: () => [],
    },
  ];

  applyFilters.forEach(({ condition, filterFn }) => {
    if (condition) {
      result = filterFn(result);
    }
  });

  return result;
};

export const applySMAListFilters = (
  filters: SMAListFilter,
  currentSelectedIds: string[],
  initialInvestmentData: CalculatedInvestmentDTO[],
) => {
  let result = initialInvestmentData;

  const applyFilters = [
    {
      condition: filters.favourites === SMAListFavouriteFilter.Favourites,
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(data, { isFavourite: true }),
    },
    {
      condition:
        filters.currentlySelected === SMAListCurrentlySelectedFilter.Selected,
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(data, (r) => currentSelectedIds.includes(r.id)),
    },
    {
      condition:
        filters.minimumInvestmentSize !==
        SMAListMinimumInvestmentSizeFilter.All,
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(
          data,
          (r) =>
            Number(r.column21.value) <=
            {
              [SMAListMinimumInvestmentSizeFilter.All]: 5000000,
              [SMAListMinimumInvestmentSizeFilter.OneHundredThousandOrLess]: 100000,
              [SMAListMinimumInvestmentSizeFilter.FiftyThousandOrLess]: 50000,
              [SMAListMinimumInvestmentSizeFilter.TwentyFiveThousandOrLess]: 25000,
              [SMAListMinimumInvestmentSizeFilter.Zero]: 0,
            }[filters.minimumInvestmentSize],
        ),
    },
    {
      condition: !isEmpty(filters.platforms),
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(data, (r) =>
          some(filters.platforms, {
            productName: r.productName,
            subProductName: r.subProductName,
          }),
        ),
    },
    {
      condition: isEmpty(filters.platforms),
      filterFn: () => [],
    },
  ];

  applyFilters.forEach(({ condition, filterFn }) => {
    if (condition) {
      result = filterFn(result);
    }
  });

  return result;
};

export const combineProductName = (
  productName: string,
  subProductName: string,
) => `${productName} ${subProductName}`;

export const combineModelName = (
  managerName: string,
  name: string,
  code: string,
) => `${managerName} ${name} ${code}`;

export const platformSortingFn = (rowA: any, rowB: any, columnId: any) => {
  const valueA = combineProductName(
    rowA.original.productName,
    rowA.original.subProductName,
  );

  const valueB = combineProductName(
    rowB.original.productName,
    rowB.original.subProductName,
  );

  return valueA.localeCompare(valueB);
};

export const modelSortingFn = (rowA: any, rowB: any, columnId: any) => {
  const valueA = combineModelName(
    rowA.original.managerName,
    rowA.original.name,
    rowA.original.shCode,
  );

  const valueB = combineModelName(
    rowB.original.managerName,
    rowB.original.name,
    rowB.original.shCode,
  );

  return valueA.localeCompare(valueB);
};

export const platformCodeSortingFn = (rowA: any, rowB: any, columnId: any) => {
  const platformA = rowA.original.platformCode;
  const platformB = rowB.original.platformCode;

  return platformA.localeCompare(platformB);
}

