import React from "react";
import { format, parse, isValid } from "date-fns";
import { DateFormat, FullDateFormat } from "@constants/format";
import { isEmpty } from "lodash";
/**
 * Wrapper component take dd/MM/yyyy date and format into dd/MM/yy date
 */
interface TableDateFormatterProps {
  dateString?: string;
}
export const TableDateFormatter = ({ dateString }: TableDateFormatterProps) => {
  if (!dateString || isEmpty(dateString)) return null;
  const parsedDate = parse(dateString, FullDateFormat, new Date());
  if (isValid(parsedDate)) {
    const formattedDate = format(parsedDate, DateFormat);
    return <>{` (to ${formattedDate})`}</>;
  } else {
    return null;
  }
};
