import { SHButton } from "@components/design-systems";
import { DownloadSVG } from "@components/svgs";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useTheme } from "@mui/material";
import { useCallback } from "react";
import { utils, writeFile } from "xlsx-js-style";
import { getWorksheet } from "@pages/managed-accounts/components/buttons/export-excel/utils";
import { ManagedAccountDTO } from "@models/managed-accounts/entities/analysis";

interface ExportMAExcelButtonProps {
  data?: ManagedAccountDTO;
  mode?: FeesDisplayStyle;
  disabled?: boolean;
}
export const ExportMAExcelButton = ({
  data,
  mode = FeesDisplayStyle.Percentage,
  disabled,
}: ExportMAExcelButtonProps) => {
  const { palette } = useTheme();

  const handleOnDownload = useCallback(() => {
    if (!data) return;
    const workbook = utils.book_new();
    const worksheet = getWorksheet(data, mode);
    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, "Analysis");
    // Save the workbook
    writeFile(workbook, `SuitabilityHub Managed Account - Fee Analysis.xlsx`);
  }, [data, mode]);

  return (
    <SHButton
      onClick={handleOnDownload}
      startIcon={
        <DownloadSVG width={12} height={13} color={palette.text.primary} />
      }
      variant="outlined"
      disableRipple
      disabled={disabled || !data}
      sx={{
        "&:active svg path": {
          fill: palette.common.white,
        },
      }}
    >
      Excel
    </SHButton>
  );
};
