import { SHTypography, SHTypographyProps } from "@components/design-systems";
import { chartTitleColor } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/configs";
import React from "react";
interface ChartTitleProps extends SHTypographyProps {
  children: React.ReactNode;
}
export const ChartTitle = ({ children, ...props }: ChartTitleProps) => {
  return (
    <SHTypography
      variant="h1"
      fontSize={"18px"}
      lineHeight={1}
      color={chartTitleColor}
      {...props}
    >
      {children}
    </SHTypography>
  );
};
