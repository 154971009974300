import {
  FeeAnalysisRowType,
  FeeAnalysisTableRow,
} from "@models/managed-accounts/entities/analysis";
import {
  CollapseType,
  MAAnalysisDataDTO,
  MAAnalysisSectionDTO,
  SelectedSubProductDTO,
} from "@models/managed-accounts/entities/step/fee";

export const flatManagedAccountFeeAnalysisData = (
  sections: MAAnalysisSectionDTO[] | any,
) => {
  let result = [] as FeeAnalysisTableRow[];
  let collapseMapping: CollapseType = {};

  sections.forEach((section: MAAnalysisSectionDTO) => {
    result.push({
      variableName: section?.variableName,
      name: section?.name,
      order: section?.order,
      description: section?.description,
      iconUrl: section?.iconUrl,
      analysisData: section?.analysisData as MAAnalysisDataDTO[],
      type: FeeAnalysisRowType.Section,
    } as FeeAnalysisTableRow);
    collapseMapping[section?.variableName ?? ""] = false;

    section.breakdowns?.forEach((breakdown: MAAnalysisSectionDTO) => {
      result.push({
        variableName: breakdown?.variableName,
        name: breakdown?.name,
        order: breakdown?.order,
        description: breakdown?.description,
        iconUrl: breakdown?.iconUrl,
        analysisData: breakdown?.analysisData as MAAnalysisDataDTO[],
        type: FeeAnalysisRowType.Breakdown,
        collapsedIds: [section?.variableName],
      } as FeeAnalysisTableRow);
    });

    section.subSections?.forEach((subSection: MAAnalysisSectionDTO) => {
      result.push({
        variableName: subSection?.variableName,
        name: subSection?.name,
        order: subSection?.order,
        description: subSection?.description,
        iconUrl: subSection?.iconUrl,
        analysisData: subSection?.analysisData as MAAnalysisDataDTO[],
        type: FeeAnalysisRowType.SubSection,
        collapsedIds: [section?.variableName],
      } as FeeAnalysisTableRow);

      collapseMapping[subSection?.variableName ?? ""] = false;
      subSection.breakdowns?.forEach((breakdown: any) => {
        result.push({
          variableName: breakdown?.variableName,
          name: breakdown?.name,
          order: breakdown?.order,
          description: breakdown?.description,
          iconUrl: breakdown?.iconUrl,
          totalFeature: breakdown?.totalFeature,
          totalSelectedFeature: breakdown?.totalFeature,
          analysisData: breakdown?.analysisData as MAAnalysisDataDTO[],
          isEssential: breakdown?.isEssential,
          type: FeeAnalysisRowType.Breakdown,
          collapsedIds: [section?.variableName, subSection?.variableName],
        } as FeeAnalysisTableRow);
      });
    });
  });

  return {
    rows: result,
    collapseMapping: collapseMapping,
  };
};

export const generateManageAccountColorCell = (
  platform?: SelectedSubProductDTO,
): "Green" | "Gray" | "Orange" | undefined => {
  if (platform?.isComingSoon) return undefined;
  if (platform?.isMissingAllInvestments) return "Orange";
  if (platform?.isMissingInvestment) return "Orange";
  return undefined;
};
