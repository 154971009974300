import { useSelector } from "react-redux";
import { ViewFeature } from "../components/view-feature";
import { RootState } from "@redux/store";
import { useEffect } from "react";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useParams } from "react-router";
import { useUserPermissions } from "@hooks/userUserPermission";
import { UpgradeSubscriptionView } from "../components/common";

export const ViewSeriesTab = () => {
  const {
    investmentProductSeriesData,
    investmentProductUI: { isSeriesDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { isAdviserTrialGroup } = useUserPermissions();
  const { investmentProductId } = useParams();
  const { loadInvestmentProductSeries } = useInvestmentProduct();

  useEffect(() => {
    if (isAdviserTrialGroup) return;

    loadInvestmentProductSeries(investmentProductId ?? "new", "view");

    // eslint-disable-next-line
  }, [investmentProductId]);

  return isAdviserTrialGroup ? (
    <UpgradeSubscriptionView />
  ) : (
    <ViewFeature
      investmentProductSeriesData={investmentProductSeriesData}
      isSeriesDataLoading={
        Boolean(investmentProductSeriesData) ? isSeriesDataLoading : true
      }
    />
  );
};
