import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { PlatformForFilterDTO } from "@models/managed-accounts/entities/filter";
import {
  InvestmentQueryOptions,
  InvestmentSelectionDTO,
} from "@models/managed-accounts/entities/step/setup";
import {
  FilterCalculatedInvestmentDTO,
  SmaListInvestmentDto,
} from "@pages/managed-accounts/sma-list/components/tables/model";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";
import { SMAListExcelExcelRequestDTO } from "@models/managed-accounts/entities/smaList";

export async function getInvestmentSelections(
  queryOptions: InvestmentQueryOptions,
): Promise<APIResponse<InvestmentSelectionDTO[]>> {
  const response = await APIGatewayService.get<InvestmentSelectionDTO[]>(
    generatePath(APIExtRoutes.investmentsSelections),
    { params: queryOptions },
  );

  return handleErrorProxy(response);
}

export async function getInvestmentFavourited(
  queryOptions: InvestmentQueryOptions,
): Promise<APIResponse<InvestmentSelectionDTO[]>> {
  const response = await APIGatewayService.get<InvestmentSelectionDTO[]>(
    generatePath(APIExtRoutes.investmentsFavourited),
    { params: queryOptions },
  );

  return handleErrorProxy(response);
}

export async function putInvestmentFavourited(
  shCodes: string[],
  investmentProductId = "",
): Promise<APIResponse<string>> {
  const apiPath =
    generatePath(APIExtRoutes.investmentsFavourited) +
    (investmentProductId !== ""
      ? `?investmentProductId=${investmentProductId}`
      : "");
  const response = await APIGatewayService.put<string>(
    generatePath(apiPath),
    shCodes,
  );

  return handleErrorProxy(response);
}

export async function getInvestmentFiltersPlatforms(
  investmentProductId = "",
): Promise<APIResponse<PlatformForFilterDTO[]>> {
  const platformPath =
    APIExtRoutes.investmentsFiltersPlatforms +
    (investmentProductId !== ""
      ? `?investmentProductId=${investmentProductId}`
      : "");
  const response = await APIGatewayService.get<PlatformForFilterDTO[]>(
    platformPath,
  );

  return handleErrorProxy(response);
}

export async function getSmaList(): Promise<APIResponse<SmaListInvestmentDto>> {
  const response = await APIGatewayService.get<SmaListInvestmentDto>(
    generatePath(APIExtRoutes.investmentsSmaList),
  );

  return handleErrorProxy(response);
}

export async function updateSmaList(
  filterCalculatedInvestmentDTO: FilterCalculatedInvestmentDTO,
): Promise<APIResponse<SmaListInvestmentDto>> {
  const response = await APIGatewayService.put<SmaListInvestmentDto>(
    generatePath(APIExtRoutes.investmentsSmaList),
    filterCalculatedInvestmentDTO,
  );

  return handleErrorProxy(response);
}

export async function exportExcelSmaList(
  smaListExcelRequestDTO: SMAListExcelExcelRequestDTO,
) {
  const response = await APIGatewayService.post<SmaListInvestmentDto>(
    generatePath(APIExtRoutes.investmentsSmaListExportExcel),
    smaListExcelRequestDTO,
  );

  return handleErrorProxy(response);
}
