import { SHStack, SHTypography } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { useTheme } from "@mui/material";
interface ProductFeaturesLayoutProps {
  hasData?: boolean;
  rightSide?: React.ReactNode;
  leftSide?: React.ReactNode;
  isLoading?: boolean;
  decreaseLeft?: boolean;
}

export const ProductFeaturesLayout = ({
  hasData = true,
  rightSide,
  leftSide,
  isLoading,
  decreaseLeft = false,
}: ProductFeaturesLayoutProps) => {
  const { palette } = useTheme();

  if (!isLoading && !hasData)
    return <SHTypography p={"5px"} variant="body1">Data coming soon</SHTypography>;

  return (
    <SHStack direction={"row"}>
      <SHStack
        width={"305px"}
        sx={{
          borderRight: isLoading ? 0 : 1,
          borderColor: palette.common.black,
        }}
      >
        {isLoading ? (
          <SHStack sx={{ mt: 3 }} spacing={1.7}>
            <SHSkeleton height={60} width={"100%"} />
            <SHSkeleton height={60} width={"100%"} />
            <SHSkeleton height={60} width={"100%"} />
            <SHSkeleton height={60} width={"100%"} />
          </SHStack>
        ) : (
          leftSide
        )}
      </SHStack>
      <SHStack
        flexGrow={1}
        width={!decreaseLeft ? "1px" : "none"}
        paddingLeft={"25px"}
        paddingTop={"25px"}
        paddingBottom={"25px"}
      >
        {isLoading ? (
          <SHStack spacing={3}>
            <SHSkeleton height={60} width={"100%"} />

            <SHStack spacing={1}>
              <SHSkeleton height={30} width={"100%"} />
              <SHSkeleton height={20} width={"100%"} />
              <SHSkeleton height={20} width={"100%"} />
            </SHStack>

            <SHStack spacing={1}>
              <SHSkeleton height={30} width={"100%"} />
              <SHSkeleton height={20} width={"100%"} />
              <SHSkeleton height={20} width={"100%"} />
            </SHStack>
          </SHStack>
        ) : (
          rightSide
        )}
      </SHStack>
    </SHStack>
  );
};
