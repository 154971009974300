import { useState } from "react";
/**
 *  Only use for collapse multiple menu sections
 */
export const useCollapseMenu = (defaultExpandIndexes: number[] = []) => {
  const [expandedIndex, setExpandedIndex] =
    useState<number[]>(defaultExpandIndexes);

  const handleCollapseToggle = (sectionIndex: number, isOpen: boolean) => {
    setExpandedIndex((prevExpandedIndex) =>
      isOpen
        ? [...prevExpandedIndex, sectionIndex]
        : prevExpandedIndex.filter((index) => index !== sectionIndex),
    );
  };

  return {
    expandedIndex,
    handleCollapseToggle,
  };
};
