import {
  SHBox,
  SHButton,
  SHHtmlBlock,
  SHStack,
} from "@components/design-systems";
import { DownloadSVG, TooltipAllowSVG } from "@components/svgs";
import { DELAY_TIME } from "@constants";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { AnalysisDTO } from "@models/platform-analysis/entities/analysis";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import {
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import { PDFPageContainer } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/pages";
import { PDFAnalysisOverviewSection } from "@pages/platform-analysis/components/buttons/export-pdf/components/sections/overview";
import {
  DEFAULT_GAP,
  PAGE_BODY_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { mockSummaryAnalysis } from "@pages/platform-analysis/components/buttons/export-pdf/mock";
import {
  PDFAnalysisControl,
  PDFAnalysisData,
  PDFFeatureAnalysis,
  PDFFeePortfolioDetail,
  PageTemplate,
} from "@pages/platform-analysis/components/buttons/export-pdf/model";
import {
  businessAnalysisPageHandler,
  commentPagesHandler,
  disclaimerPageHandler,
  featureAnalysisPageHandler,
  featureIncludedPageHandler,
  feesAnalysisGraphPageHandler,
  feesAnalysisPageHandler,
  generatePDFData,
  objectivesPageHandler,
  platformsIncludedPageHandler,
  portfolioDetailPageHandler,
} from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { Document, PDFViewer, View, pdf } from "@react-pdf/renderer";
import { compilePlatformAnalysis } from "@services/platform-analysis/util";
import { hexToRGBA } from "@utils";
import { saveAs } from "file-saver";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

interface ExportPDFButtonProps {
  isCompleted?: boolean;
}

export const ExportPDFButton = ({
  isCompleted,
}: ExportPDFButtonProps): JSX.Element => {
  const { platformAnalysis, disclaimers, lineChartColors } =
    usePlatformAnalysis();
  const { palette, shadows } = useTheme();
  const [isDownloading, setIsDownloading] = useState(false);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    setIsDisable(isDownloading || !isCompleted || !platformAnalysis);
  }, [isDownloading, isCompleted, platformAnalysis]);

  const handleOnDownloadPDF = async () => {
    if (!platformAnalysis) return <></>;
    setIsDownloading(true);
    const blob = await pdf(
      <PDFDocument
        platformAnalysis={platformAnalysis}
        disclaimers={disclaimers}
        lineChartColors={lineChartColors}
      />,
    ).toBlob();
    saveAs(
      blob,
      `SuitabilityHub Platform Analysis Summary - ${platformAnalysis?.summary?.name}.pdf`,
    );
    setIsDownloading(false);
  };

  const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      paddingRight: "0px"
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
      {
        marginTop: "-1px",
      },
  }));

  return (
    <TooltipStyled
      placement="top-end"
      enterDelay={DELAY_TIME}
      enterNextDelay={DELAY_TIME}
      title={
        (isDisable && !isDownloading) ? (
          <SHStack
            sx={{
              p: 1,
              mt: "3px",
              maxWidth: "190px",
              minHeight: "20px",
              bgcolor: hexToRGBA(palette.common.white, 0.85),
              border: "1px solid #E3E3E3",
              backdropFilter: "blur(2px)",
              borderRadius: "3px",
              boxShadow: shadows[1],
              position: "relative",
            }}
          >
            <SHHtmlBlock
              variant="body3"
              color={palette.text.disabled}
              content={"Complete your analysis to download it as PDF"}
              textAlign={"left"}
            />
            <SHBox sx={{ position: "absolute", top: "-8px", left: "82%" }}>
              <TooltipAllowSVG transform={"rotate(180)"} />
            </SHBox>
          </SHStack>
        ) : (
          ""
        )
      }
    >
      <SHBox component={"span"}>
        <SHButton
          variant="contained"
          size="extraMedium"
          startIcon={<DownloadSVG />}
          disabled={isDisable}
          isLoading={isDownloading}
          onClick={() => handleOnDownloadPDF()}
          sx={{
            "&.Mui-disabled.sh-btn-with-icon": {
              border: `1px solid ${hexToRGBA(palette.primary.dark, 0.3)}`,
              backgroundColor: palette.primary[50],
              color: palette.common.white,
            },
          }}
        >
          PDF
        </SHButton>
      </SHBox>
    </TooltipStyled>
  );
};

const PDFDocument = ({
  platformAnalysis,
  disclaimers,
  lineChartColors,
}: {
  platformAnalysis: AnalysisDTO;
  disclaimers?: DisclaimerDTO[];
  lineChartColors?: { [key in string]: string };
}): JSX.Element => {
  const generatePDFPages = (pdfData: PDFAnalysisData) => {
    let pages: PageTemplate[] = [];
    let currentSteps: AnalysisStep[] = [];
    let spaceAvailable = PAGE_BODY_HEIGHT;
    const { Feature, BusinessMetric, Fee } = AnalysisStep;
    const {
      showFeeStep,
      showFeatureStep,
      showBusinessMetricStep,
      showSubSection,
      showComments,
      showSelectedFeatures,
      showHoldingNumber,
      showAnalysisScore,
      showFeeAnalysisGraph,
    } = pdfData?.controls as PDFAnalysisControl;

    if (showFeatureStep) currentSteps.push(Feature);
    if (showBusinessMetricStep) currentSteps.push(BusinessMetric);
    if (showFeeStep) currentSteps.push(Fee);

    const selectedProducts = pdfData?.selectedProducts ?? [];

    pages?.push({
      components: [
        <View style={{ gap: DEFAULT_GAP }}>
          <PDFAnalysisOverviewSection overview={pdfData?.overview} />
        </View>,
      ],
    });

    if (pdfData.objectives) {
      spaceAvailable = objectivesPageHandler(
        pages,
        pdfData.objectives,
        spaceAvailable,
        false,
        false,
      );
    }

    if (showFeatureStep) {
      spaceAvailable = featureAnalysisPageHandler(
        pages,
        spaceAvailable,
        pdfData?.featureAnalysis as PDFFeatureAnalysis,
        showSubSection,
        pdfData?.displayModes?.featureAnalysisTableViewMode ===
          TableViewMode.Detail,
        showAnalysisScore,
      );
    }

    if (showBusinessMetricStep) {
      spaceAvailable = businessAnalysisPageHandler(
        pages,
        spaceAvailable,
        pdfData?.businessAnalysis as PDFFeatureAnalysis,
      );
    }

    if (showFeeStep) {
      spaceAvailable = portfolioDetailPageHandler(
        pages,
        spaceAvailable,
        pdfData?.feePortfolioDetails as PDFFeePortfolioDetail,
        showHoldingNumber,
      );
      if (!pdfData?.feeAnalysis) return;
      spaceAvailable = feesAnalysisPageHandler(
        pages,
        spaceAvailable,
        pdfData?.feeAnalysis,
      );

      if (showFeeAnalysisGraph && !isEmpty(pdfData.feeAnalysis.subProducts)) {
        spaceAvailable = feesAnalysisGraphPageHandler(
          pages,
          spaceAvailable,
          pdfData.feeAnalysis.subProducts,
          pdfData.feeAnalysis.feesDisplayStyle,
          platformAnalysis?.summary?.feeAnalysis?.subProducts,
          lineChartColors,
        );
      }
    }

    if (showComments) {
      spaceAvailable = commentPagesHandler(
        pages,
        spaceAvailable,
        pdfData?.analysisComments ?? [],
        currentSteps,
      );
    }

    if (!isEmpty(pdfData?.concludingRemarks)) {
      if (pdfData?.concludingRemarks) {
        spaceAvailable = objectivesPageHandler(
          pages,
          pdfData?.concludingRemarks,
          spaceAvailable,
          true,
          false,
        );
      }
    }

    spaceAvailable = platformsIncludedPageHandler(
      pages,
      spaceAvailable,
      selectedProducts,
    );

    if (showSelectedFeatures && showFeatureStep) {
      spaceAvailable = featureIncludedPageHandler(
        pages,
        spaceAvailable,
        pdfData?.featureIncluded,
      );
    }

    disclaimerPageHandler(
      pages,
      spaceAvailable,
      disclaimers ?? [],
      showFeeStep,
    );

    return pages;
  };

  const pdfData = generatePDFData(platformAnalysis);
  const pages = generatePDFPages(pdfData);

  return (
    <Document>
      {pages?.map((page, index) => (
        <PDFPageContainer
          key={index}
          pageIndex={index + 1}
          totalPage={pages?.length}
          footerData={pdfData?.overview}
          isFirstPage={index === 0}
        >
          {page.components?.map((el) => el)}
        </PDFPageContainer>
      ))}
    </Document>
  );
};

// Note: For development and testing
export const ExportPDFPreview = (): JSX.Element => {
  return (
    <SHStack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <PDFViewer width={"90%"} height={950}>
        <PDFDocument
          platformAnalysis={compilePlatformAnalysis(mockSummaryAnalysis)}
        />
      </PDFViewer>
    </SHStack>
  );
};
