import { SectionType } from "@models/configuration";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import {
  InvestmentDataStatus,
  InvestmentProductDataTab,
} from "@models/product/investment-product/enums/status";

export const investmentProductViewTabsData: InvestmentConfigurationGroup[] = [
  {
    id: "1",
    name: InvestmentProductDataTab.Manager,
    sectionType: SectionType.Standard,
    order: 1,
    dataStatus: InvestmentDataStatus.Filled,
  },
  {
    id: "2",
    name: InvestmentProductDataTab.Series,
    sectionType: SectionType.Fee,
    order: 2,
    dataStatus: InvestmentDataStatus.Filled,
  },
  {
    id: "3",
    name: InvestmentProductDataTab.Models,
    sectionType: SectionType.HasSubSections,
    order: 3,
    dataStatus: InvestmentDataStatus.Filled,
  },
  {
    id: "4",
    name: InvestmentProductDataTab.Availability,
    sectionType: SectionType.SMAList,
    order: 4,
    dataStatus: InvestmentDataStatus.Filled,
  },
  {
    id: "5",
    name: InvestmentProductDataTab.ESG,
    sectionType: SectionType.Standard,
    order: 5,
    dataStatus: InvestmentDataStatus.Filled,
  },
];

export const investmentProductEditTabsData: InvestmentConfigurationGroup[] = [
  {
    id: "1",
    name: InvestmentProductDataTab.Manager,
    sectionType: SectionType.Standard,
    order: 1,
    dataStatus: InvestmentDataStatus.Filled,
  },
  {
    id: "2",
    name: InvestmentProductDataTab.Series,
    sectionType: SectionType.Fee,
    order: 2,
    dataStatus: InvestmentDataStatus.Filled,
  },
  {
    id: "3",
    name: InvestmentProductDataTab.Models,
    sectionType: SectionType.HasSubSections,
    order: 3,
    dataStatus: InvestmentDataStatus.Filled,
  },
  {
    id: "4",
    name: InvestmentProductDataTab.ESG,
    sectionType: SectionType.Standard,
    order: 4,
    dataStatus: InvestmentDataStatus.Filled,
  },
];
