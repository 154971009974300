import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { sortAscByOrder } from "@utils";
import { isEmpty } from "lodash";

export const compileInvestmentProductGroups = (
  isNew: boolean,
  groups?: InvestmentConfigurationGroup[],
): InvestmentConfigurationGroup[] | undefined => {
  if (!groups || isEmpty(groups)) return undefined;

  const sortedGroups: InvestmentConfigurationGroup[] = sortAscByOrder(groups);

  if (isNew) {
    sortedGroups.forEach(
      (gr) => (gr.dataStatus = InvestmentDataStatus.MissingData),
    );
  }

  return sortedGroups;
};
