import {
  DefaultCellHeight,
  fillBlue,
  fillLightBlue,
  fillYellow,
  fillLightYellow,
  exportDate,
  exportPercentage,
  exportCurrency,
  exportNoDecimalPercentage,
  exportNoDecimalCurrency,
  CharactersInARow,
  CharactersInACell,
} from "./config";
import { Range, utils, WorkSheet } from "xlsx-js-style";
import { drawBorders } from "@pages/managed-accounts/components/buttons/export-excel/utils";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import {
  SMAListExcelDTO,
  SMAListExcelSelectedInvestmentDTO,
} from "@models/managed-accounts/entities/smaList";
import { isNaN } from "lodash";
import { parse, isValid } from "date-fns";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import { MAAnalysisTooltipDTO } from "@models/managed-accounts/entities/step/fee";

let rows: any[] = [];
let merges: Range[] = [];
let rowsHeightTrack: Map<number, number> = new Map();
let feeLength = 0;
let smaListExcelData = new SMAListExcelDTO();
let viewModeExcel = "";
let isShowCmFeeFooter = false;

function pushHeaderTitle(label: string) {
  rows.push(["", ""]);
  rowsHeightTrack.set(1, DefaultCellHeight * 2);
  rows.push([
    "",
    {
      v: label,
      t: "s",
      s: {
        fill: fillBlue,
        font: {
          bold: true,
          sz: 14,
        },
      },
    },
    ...[...Array(feeLength)].map(() => ({
      v: "",
      t: "s",
      s: {
        fill: fillBlue,
      },
    })),
  ]);
}

function pushSectionTitle(str: string) {
  rows.push([
    "",
    {
      v: str,
      t: "s",
      s: {
        fill: fillBlue,
        font: {
          bold: true,
        },
      },
    },
    ...[...Array(feeLength)].map(() => ({
      v: "",
      t: "s",
      s: {
        fill: fillBlue,
      },
    })),
  ]);
}

function pushHeaderData() {
  rows.push([
    "",
    `For ${smaListExcelData.supplierName} internal use only. Not to be disseminated`,
  ]);
  rows.push(["", ""]);
  
  const ownerName = `${smaListExcelData.ownerName} of ${smaListExcelData.supplierName}`;
  if(ownerName.length >= CharactersInACell){
    var ownerNameHeight = (Math.ceil(ownerName.length / CharactersInACell) + 1) * DefaultCellHeight;
    rowsHeightTrack.set(4, ownerNameHeight);
  }
  
  rows.push([
    "",
    {
      v: "Owner:",
      t: "t",
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    {
      v: ownerName,
      t: "t",
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
          wrapText: 1,
        },
      },
    },
  ]);
  rows.push([
    "",
    "Data valid:",
    {
      v: smaListExcelData.dataValidDate,
      ...exportDate,
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
  ]);
  rows.push([
    "",
    "Holding value:",
    {
      v: smaListExcelData.investmentSize,
      t: "n",
      z: getDecimalFormat(smaListExcelData.investmentSize.toString(), false),
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
  ]);
  rows.push(["", ""]);
}

function pushModelData() {
  merges.push({
    s: {
      r: 9,
      c: 1,
    },
    e: {
      r: 10,
      c: 1,
    },
  });
  rows.push([
    "",
    {
      v: "Model",
      t: "s",
      s: {
        fill: fillLightBlue,
        font: {
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        if (investment.managerName.length > CharactersInACell) {
          rowsHeightTrack.set(rows.length, DefaultCellHeight * 2);
        }
        return {
          v: `${investment.managerName}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
  rows.push([
    "",
    {
      v: "",
      t: "s",
      s: {
        fill: fillLightBlue,
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        if (investment.name.length > CharactersInACell) {
          rowsHeightTrack.set(rows.length, DefaultCellHeight * 2);
        }
        return {
          v: `${investment.name}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
}

function pushPlatformData() {
  merges.push({
    s: {
      r: 11,
      c: 1,
    },
    e: {
      r: 12,
      c: 1,
    },
  });
  rows.push([
    "",
    {
      v: "Platform",
      t: "s",
      s: {
        fill: fillLightBlue,
        font: {
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        if (investment.productName.length > CharactersInACell) {
          rowsHeightTrack.set(rows.length, DefaultCellHeight * 2);
        }
        return {
          v: `${investment.productName}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
  rows.push([
    "",
    {
      v: "",
      t: "s",
      s: {
        fill: fillLightBlue,
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        if (investment.subProductName.length > CharactersInACell) {
          rowsHeightTrack.set(rows.length, DefaultCellHeight * 2);
        }
        return {
          v: `${investment.subProductName}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
}

function pushPlatformCodeData() {
  rowsHeightTrack.set(rows.length, DefaultCellHeight);
  rows.push([
    "",
    {
      v: "Code",
      t: "s",
      s: {
        fill: fillLightBlue,
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        return {
          v: investment.platformCode,
          t: "s",
          s: {
            fill: fillLightBlue,
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        };
      },
    ),
  ]);
}

function pushSectionsData() {
  smaListExcelData.sections.forEach((section) => {
    const analysisData = section.analysisData;
    const subSections = section.subSections;
    const breakdowns = section.breakdowns;

    if (analysisData) {
      rows.push([
        "",
        {
          v: section.name,
          t: "s",
          s: {
            fill: fillYellow,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "left",
            },
          },
        },
        ...analysisData?.map((analysis) => {
          return {
            ...getValueFormatType(
              analysis.value ?? "",
              analysis.valueInPercent ?? "",
              analysis.fieldTypeId ?? "",
              section.variableName,
              analysis.tooltip,
            ),
            s: {
              fill: fillYellow,
              font: {
                bold: true,
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
            },
          };
        }),
      ]);
    } else {
      rows.push([
        "",
        {
          v: section.name,
          t: "s",
          s: {
            fill: fillYellow,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "left",
            },
          },
        },
        ...Array.from(
          Array(smaListExcelData.selectedInvestments.length).keys(),
        ).map(() => {
          return {
            v: "",
            t: "s",
            s: {
              fill: fillYellow,
            },
          };
        }),
      ]);
    }

    if (breakdowns) {
      breakdowns.forEach((breakdown) => {
        const breakdownAnalysisData = breakdown.analysisData || [];

        if (breakdown.name.length > CharactersInARow) {
          rowsHeightTrack.set(rows.length, DefaultCellHeight * 2);
        }

        rows.push([
          "",
          {
            v: breakdown.name,
            t: "s",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "left",
                wrapText: true,
              },
            },
          },
          ...breakdownAnalysisData.map((analysis) => {
            return {
              ...getValueFormatType(
                analysis.value ?? "",
                analysis.valueInPercent ?? "",
                analysis.fieldTypeId ?? "",
                breakdown.variableName,
                analysis.tooltip,
              ),
              s: {
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                },
              },
            };
          }),
        ]);
      });
    }

    if (subSections) {
      subSections.forEach((subSection) => {
        const subSectionAnalysisData = subSection.analysisData ?? [];
        const subSectionFeeBreakdown = subSection.breakdowns ?? [];
        const renderAnalysisCells =
          subSectionAnalysisData.length === 0
            ? Array.from(
                Array(smaListExcelData.selectedInvestments.length).keys(),
              ).map(() => {
                return {
                  v: "",
                  t: "s",
                  s: {
                    fill: fillLightYellow,
                  },
                };
              })
            : subSectionAnalysisData.map((analysis) => {
                return {
                  ...getValueFormatType(
                    analysis.value ?? "",
                    analysis.valueInPercent ?? "",
                    analysis.fieldTypeId ?? "",
                    subSection.variableName,
                    analysis.tooltip,
                  ),
                  s: {
                    fill: fillLightYellow,
                    font: {
                      bold: true,
                    },
                    alignment: {
                      vertical: "center",
                      horizontal: "center",
                    },
                  },
                };
              });

        rows.push([
          "",
          {
            v: subSection.name,
            t: "s",
            s: {
              fill: fillLightYellow,
              font: {
                bold: true,
              },
              alignment: {
                vertical: "center",
                horizontal: "left",
              },
            },
          },
          ...renderAnalysisCells,
        ]);
        subSectionFeeBreakdown.forEach((feeBreakdown) => {
          const feeBreakdownAnalysisData = feeBreakdown.analysisData || [];

          if (feeBreakdown.name.length > CharactersInARow) {
            rowsHeightTrack.set(rows.length, DefaultCellHeight * 2);
          }

          rows.push([
            "",
            {
              v: feeBreakdown.name,
              t: "s",
              s: {
                alignment: {
                  vertical: "center",
                  horizontal: "left",
                  wrapText: true,
                },
              },
            },
            ...feeBreakdownAnalysisData.map((analysis) => {
              return {
                ...getValueFormatType(
                  analysis.value ?? "",
                  analysis.valueInPercent ?? "",
                  analysis.fieldTypeId ?? "",
                  feeBreakdown.variableName,
                  analysis.tooltip,
                ),
                s: {
                  alignment: {
                    vertical: "center",
                    horizontal: "center",
                  },
                },
              };
            }),
          ]);
        });
      });
    }
  });
}

function pushFooterData() {
  rows.push(["", ""]);
  rows.push(["", "*See web version for detailed breakdown"]);

  if (isShowCmFeeFooter) {
    rows.push([
      "",
      "**Cash fees not directly disclosed may be included within indirect or other management fees and costs",
    ]);
  }

  merges.push({
    s: {
      r: rows.length,
      c: 1,
    },
    e: {
      r: rows.length,
      c: 2,
    },
  });
  rows.push([
    "",
    "SMA fee analysis disclaimer and assumptions apply. See web version for details",
  ]);
}

function getDecimalFormat(excelValue: string, isPercentage: boolean) {
  const excelValueFloor = Math.floor(+excelValue);
  const decimalPart = +excelValue - excelValueFloor;

  if (isPercentage) {
    return decimalPart === 0 ? exportNoDecimalPercentage.z : exportPercentage.z;
  }

  return decimalPart === 0 ? exportNoDecimalCurrency.z : exportCurrency.z;
}

function getValueFormatType(
  value: string,
  valueInPercent: string,
  fieldType: string,
  variableName = "",
  tooltips: MAAnalysisTooltipDTO | null,
) {
  const formatCellValue = {
    v: "",
    t: "s",
    z: "",
  };
  const alwaysShowPercentageColumns = [
    "Alloc_AU_cash",
    "Alloc_Int_listed",
    "M_turnover",
  ];

  const getNoDataValue = (value: string | null) => {
    return value === null || value === "" ? "No data" : value;
  };

  switch (fieldType) {
    case ManagedAccountFieldType.Currency:
    case ManagedAccountFieldType.Percentage:
      const isPercentage =
        variableName === "Model_min_inv"
          ? false
          : alwaysShowPercentageColumns.includes(variableName)
          ? true
          : viewModeExcel === FeesDisplayStyle.Percentage;
      const valueStr = isPercentage ? valueInPercent : value;

      if (isNaN(+valueStr)) {
        if (valueStr && valueStr.toLowerCase().includes("disclosed")) {
          formatCellValue.v =
            variableName === "CM_fee" ? `${valueStr}**` : `${valueStr}*`;
          isShowCmFeeFooter = variableName === "CM_fee";
        } else {
          formatCellValue.v = getNoDataValue(valueStr);
        }
        return formatCellValue;
      }

      if (tooltips !== null) {
        formatCellValue.v = isPercentage ? `${valueStr}%*` : `$${valueStr}*`;
      } else {
        formatCellValue.v = isPercentage
          ? (+valueStr / 100).toString()
          : valueStr;
        formatCellValue.t = "n";
        formatCellValue.z = getDecimalFormat(valueStr, isPercentage);
      }

      return formatCellValue;
    case ManagedAccountFieldType.DateTime:
      const dateParsed = parse(value, "dd/MM/yy", new Date());
      const isValidDate = isValid(dateParsed);

      if (isValidDate) {
        if (tooltips !== null) {
          formatCellValue.v = `${value}*`;
          return formatCellValue;
        } else {
          return {
            v: dateParsed,
            ...exportDate,
          };
        }
      }

      formatCellValue.v = getNoDataValue(value);

      return formatCellValue;
    case ManagedAccountFieldType.YesNo:
    case ManagedAccountFieldType.YesNoText:
      const booleanValue = value !== null ? value.toLowerCase() : "";

      if (
        !(
          booleanValue === "true" ||
          booleanValue === "false" ||
          booleanValue === "no rebates"
        )
      ) {
        formatCellValue.v = getNoDataValue(value);
        return formatCellValue;
      }

      if (tooltips !== null) {
        formatCellValue.v =
          booleanValue === "no rebates"
            ? `No rebates*`
            : booleanValue === "true"
            ? "Yes*"
            : "No*";
      } else {
        formatCellValue.v =
          booleanValue === "no rebates"
            ? "No rebates"
            : booleanValue === "true"
            ? "Yes"
            : "No";
      }

      return formatCellValue;
    default:
      formatCellValue.v =
        tooltips !== null ? `${value}*` : getNoDataValue(value);

      return formatCellValue;
  }
}

export function getWorkSheet(
  data?: SMAListExcelDTO,
  viewMode: FeesDisplayStyle = FeesDisplayStyle.Percentage,
) {
  if (!data) return utils.aoa_to_sheet([]);

  smaListExcelData = data;
  viewModeExcel = viewMode;
  feeLength = smaListExcelData.selectedInvestments.length;
  pushHeaderTitle("SuitabilityHub SMA portfolio fee breakdown");
  pushHeaderData();
  pushSectionTitle("Fee estimates: Portfolio fees by platform");
  pushModelData();
  pushPlatformData();
  pushPlatformCodeData();
  pushSectionsData();
  pushFooterData();

  const worksheet: WorkSheet = utils.aoa_to_sheet(rows);
  const wscols = [{ wch: 2 }, { wch: 60 }, { wch: 18 }];

  Array.from(Array(feeLength).keys()).forEach(() => wscols?.push({ wch: 18 }));
  worksheet["!cols"] = wscols;
  worksheet["!merges"] = merges;
  worksheet["!rows"] = rows.map((row, index) => ({
    hpx: rowsHeightTrack.get(index) ?? DefaultCellHeight,
  }));
  drawBorders(worksheet);
  rows = [];
  merges = [];
  rowsHeightTrack = new Map();
  isShowCmFeeFooter = false;

  return worksheet;
}
