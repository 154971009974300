import {
  SHBox,
  SHButton,
  SHContainer,
  SHRichTextEditor,
  SHStack,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

export class HTMLConvertor {
  content: string = "";
}

export const ConvertToHtml = () => {
  const { palette } = useTheme();
  const { notify } = useNotification();
  const formControls = useForm({
    mode: "onChange",
    defaultValues: {
      ...new HTMLConvertor(),
    },
  });

  const { control, getValues, reset } = formControls;

  const [text, setText] = useState<string>("");

  const convertToHtml = () => {
    const value = getValues();
    setText(value.content);
  };

  const clearData = () => {
    reset();
    setText("");
  };

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, marginY: 5 }}>
      <SHBox component={"form"}>
        <SHStack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <SHRichTextEditor
                {...field}
                maxLength={5000}
                height={500}
                // label={"Text to convert"}
                sx={{ marginTop: 0 }}
              />
            )}
          />
          <SHStack
            direction={"column"}
            justifyContent={"space-between"}
            height={100}
          >
            <SHButton
              variant="contained"
              sx={{ width: "100px" }}
              onClick={convertToHtml}
            >
              Convert
            </SHButton>
            <SHButton
              variant="outlined"
              sx={{ width: "100px" }}
              onClick={clearData}
            >
              Clear
            </SHButton>
          </SHStack>

          <SHStack alignItems={"center"}>
            <textarea
              value={text}
              style={{
                resize: "none",
                outline: "none",
                height: 500,
                width: "500px",
                // backgroundColor: "cyan",
                marginBottom: "10px",
                borderColor: palette.grey[300],
                borderWidth: "1px 1px",
                borderStyle: "solid",
                fontFamily: "Epilogue, sans-serif",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.4,
                color: palette.text.primary,
                padding: 15,
              }}
            />
            <SHButton
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(text);
                notify("Data copied to clipboard", {
                  variant: "success",
                  close: true,
                });
              }}
            >
              Copy to Clipboard
            </SHButton>
          </SHStack>
        </SHStack>
      </SHBox>
    </SHContainer>
  );
};
