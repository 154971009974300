import { SHTypography } from "@components/design-systems";
import { DisclaimerAssumptionsButton } from "@components/buttons/disclaimer-assumptions";
import { SMATableDisclaimerDialog } from "@pages/managed-accounts/sma-list/components/dialogs/disclaimer";
import { useState } from "react";

const AvailabilityFooterDisclaimer = () => {
  const [showTableDisclaimer, setShowTableDisclaimer] =
    useState<boolean>(false);

  return (
    <>
      <SHTypography variant="body4" fontSize={"10px"}>
        By using this table, you confirm that you have read and accepted the{" "}
        <DisclaimerAssumptionsButton
          onClick={() => setShowTableDisclaimer(true)}
        />
      </SHTypography>

      {showTableDisclaimer && (
        <SMATableDisclaimerDialog
          onClose={() => setShowTableDisclaimer(false)}
        />
      )}
    </>
  );
};

export default AvailabilityFooterDisclaimer;
