import { SHStack, SHTypography } from "@components/design-systems";
import { ExportMAListExcelButton } from "@pages/managed-accounts/sma-list/components/buttons/export-excel";

const ExcelTopBar = () => {
  return (
    <SHStack
      justifyContent={"space-between"}
      direction={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "start", sm: "center" }}
      spacing={1}
    >
      <SHTypography variant="subtitle1">Platform availability</SHTypography>
      <ExportMAListExcelButton  />
    </SHStack>
  );
};

export default ExcelTopBar;
