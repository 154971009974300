import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { RootState } from "@redux/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { UpgradeSubscriptionView } from "../components/common";
import { ViewStandard } from "../components/view-standard";
import { SHStack, SHTypography } from "@components/design-systems";
import { useTheme } from "@mui/material";

export const ViewESGTab = () => {
  const { palette } = useTheme();
  const {
    investmentProductUI: { isEsgDataLoading },
    investmentProductEsgData,
  } = useSelector((state: RootState) => state.investmentProduct);
  const { isAdviserTrialGroup } = useUserPermissions();
  const { investmentProductId } = useParams();
  const { loadInvestmentProductEsg } = useInvestmentProduct();

  useEffect(() => {
    if (isAdviserTrialGroup) return;

    loadInvestmentProductEsg(investmentProductId ?? "new", "view");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductId]);

  return isAdviserTrialGroup ? (
    <UpgradeSubscriptionView />
  ) : (
    <SHStack>
      <SHTypography
        variant="subtitle1"
        color={palette.text.primary}
        paddingY="25px"
      >
        Investment manager ESG profile
      </SHTypography>

      <ViewStandard
        sections={investmentProductEsgData?.group?.sections}
        isLoadingProductData={
          Boolean(investmentProductEsgData) ? isEsgDataLoading : true
        }
        investmentProductUpdateDate={
          investmentProductEsgData?.group?.pageUpdated
        }
      />
    </SHStack>
  );
};
