export enum ManagedAccountFieldType {
  Currency = "Currency",
  YesNo = "YesNo",
  YesNoText = "YesNoText",
  Multiple = "Multiple",
  DateTime = "DateTime",
  Percentage = "Percentage",
  Warning = "Warning",
  Dropdown = "Dropdown",
  Text = "Text"
}
