import { DataStatusBadge } from "@components/data-status-badge";
import {
  SHButton,
  SHDataGrid,
  SHSearchBoxProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { DateTimeFormat } from "@constants/format";
import { useEditProduct } from "@hooks/useEditProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar } from "@layouts/top-bar";
import { ProductDTO } from "@models/product/entities/product";
import { useTheme } from "@mui/material";
import { ProductBeingKickedOutDialog } from "@pages/platform-profiles/components/dialog-being-kick-out";
// import { PermissionDeniedDialog } from "@pages/platform-profiles/components/dialog-claiming-denied";
import { ProductListDataStatus } from "@models/product/enums/productListDataStatus";
import { ProductStatus } from "@models/product/enums/status";
import { ConfirmKickOutDialog } from "@pages/platform-profiles/components/dialog-confirm-kick-out";
import {
  ProductDataStatusFilterOptions,
  ProductStatusFilterOptions,
  ProductTypeFilterOptions,
} from "@pages/platform-profiles/constant";
import { updateEditMode } from "@redux/slices/product";
import { RootState } from "@redux/store";
import { getProductNavigationPath } from "@services/product/util";
import { createColumnHelper, TableState } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ConfirmKickOutAdminDialog } from "../dialog-kick-out-admin";
import { ProductType } from "@models/product/enums/productType";

interface PlatformProfileListViewProps {
  oDataUrl: string;
  showTitle?: boolean;
  defaultState?: Partial<TableState>;
  emptyMessage?: string;
  rightToolbar?: React.ReactNode;
  searchBoxProps?: SHSearchBoxProps;
}

const pickColumns: (keyof ProductDTO)[] = [
  "id",
  "supplierId",
  "productName",
  "productType",
  "supplierName",
  "lastModifiedDate",
  "status",
  "dataStatus",
];
const columnHelper = createColumnHelper<ProductDTO>();
const nameOf = nameOfFactory<ProductDTO>();

export default function PlatformProfileListView({
  oDataUrl,
  showTitle = false,
  defaultState,
  emptyMessage,
  rightToolbar,
  searchBoxProps,
}: PlatformProfileListViewProps): JSX.Element {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { checkEditProduct } = useEditProduct();
  const [supplierId, setSupplierId] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  const {
    productUI: { isLoadingEditMode },
  } = useSelector((state: RootState) => state.product);
  const { canGoToEditProduct, canViewProductStatus } = useUserPermissions();

  const [selectedProductId, setSelectedProductId] = useState("");

  const columns = useMemo(
    () => [
      columnHelper.accessor("productName", {
        header: "Product name",
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor("productType", {
        header: "Type",
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
        meta: {
          filterData: ProductTypeFilterOptions,
        },
      }),
      columnHelper.accessor("supplierName", {
        header: "Supplier",
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor("lastModifiedDate", {
        header: "Last modified",
        cell: (props) => {
          const date = props.cell.getValue();
          return date ? format(new Date(date), `${DateTimeFormat}`) : "";
        },
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => (
          <StatusBadge status={props.cell.getValue() as ProductStatus} />
        ),
        meta: {
          filterData: ProductStatusFilterOptions,
          sx: {
            width: "140px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Data status",
        id: nameOf("dataStatus"),
        cell: (props) =>
          !canViewProductStatus(props.cell.getValue()?.id) ? null : (
            <DataStatusBadge
              status={props.cell.getValue().dataStatus as ProductListDataStatus}
            />
          ),
        meta: {
          filterData: ProductDataStatusFilterOptions,
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => (
          <SHStack spacing={2} direction="row">
            <SHButton
              startIcon={<EditSVG color={palette.common.white} />}
              variant="contained"
              disabled={
                isLoadingEditMode ||
                !canGoToEditProduct(props.cell.getValue()?.id)
              }
              isLoading={isLoadingEditMode}
              onClick={() => {
                const currentCell = props.cell.getValue();
                if (currentCell.productType === ProductType.Platform) {
                  const productId = currentCell?.id || "";
                  const supplierId = currentCell?.supplierId;
                  setSelectedProductId(productId);
                  setSupplierId(supplierId);
                  checkEditProduct(productId, () => {
                    dispatch(updateEditMode(true));
                    navigate(getProductNavigationPath(currentCell));
                  });
                }
              }}
            >
              Edit
            </SHButton>
            <SHButton
              variant="outlined"
              onClick={() => {
                const currentCell = props.cell.getValue();
                setSupplierId(props.cell.getValue()?.supplierId);
                dispatch(updateEditMode(false));
                navigate(getProductNavigationPath(currentCell));
              }}
            >
              View
            </SHButton>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "190px",
          },
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <SHStack spacing={3} sx={{ paddingY: showTitle ? 3 : 0 }}>
      {showTitle && <TopBar title={"Product profiles "} />}
      <SHDataGrid
        state={defaultState}
        columns={columns as any}
        emptyMessage={emptyMessage}
        searchBoxProps={searchBoxProps}
        rightToolbar={rightToolbar}
        odata={{
          url: oDataUrl,
          pickColumns: pickColumns,
          globalFilterColumns: [nameOf("productName")],
        }}
      />
      {/* <PermissionDeniedDialog /> */}
      <ConfirmKickOutAdminDialog
        supplierId={supplierId}
        productId={selectedProductId}
      />
      <ConfirmKickOutDialog
        supplierId={supplierId}
        productId={selectedProductId}
      />
      <ProductBeingKickedOutDialog />
    </SHStack>
  );
}
