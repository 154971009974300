import { SHIconLoading } from "@components/design-systems";
import { RecycleBinSVG } from "@components/svgs";
import { DELAY_TIME } from "@constants";
import { IconButton, Tooltip, tooltipClasses, useTheme } from "@mui/material";
export interface CloseButtonProps {
  width?: string;
  height?: string;
  borderColor?: string;
  backgroundColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  [key: string]: any;
  iconColor?: string;
}
export const DeleteButton = ({
  width = "24px",
  height = "24px",
  borderColor,
  backgroundColor,
  isLoading,
  disabled,
  iconColor,
  ...others
}: any) => {
  const { shadows, palette } = useTheme();
  return (
    <Tooltip
      title="Remove"
      enterDelay={DELAY_TIME}
      enterNextDelay={DELAY_TIME}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "6px",
              },
          },
        },
      }}
    >
      <IconButton
        disableRipple
        disabled={isLoading || disabled}
        {...others}
        sx={{
          width,
          height,
          p: 0,
          borderStyle: "solid",
          borderWidth: "1px",
          backgroundColor: backgroundColor ?? palette.error[50],
          borderColor: borderColor ?? palette.error[100],
          boxShadow: shadows[1],
          "&:hover": {
            backgroundColor: palette.error.light,
            borderColor: palette.error.dark,
            boxShadow: "none",
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          "&:active": {
            backgroundColor: palette.error.light,
            borderColor: palette.error.dark,
            boxShadow: `inset 1.5px 1.5px 1.5px ${palette.error.dark}`,
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          "&:disabled": {
            border: `1px solid ${palette.text.disabled}`,
            backgroundColor: palette.grey[400],
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          ...others.sx,
        }}
      >
        {isLoading ? (
          <SHIconLoading size={15} />
        ) : (
          <RecycleBinSVG color={iconColor} />
        )}
      </IconButton>
    </Tooltip>
  );
};
