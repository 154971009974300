import { SHBox, SHStack, SHTypography } from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { useTheme, Zoom } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import { HtmlTooltip } from "@pages/platform-analysis/_id/steps/fee/analysis/components/sub-product-card";
import { hexToRGBA } from "@utils";
import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { useToggle, useWindowSize } from "react-use";

export const UserInforMationCardTooltip = (params: {
  children: JSX.Element;
  tooltipContent?: string | null;
}) => {
  const { palette } = useTheme();
  const windowSize = useWindowSize();

  const [isTooltipOpening, openTooltip] = useToggle(false);
  const [isFlipped, toggleFlipped] = useToggle(false);
  const [isClamped, toggleIsClamped] = useToggle(false);

  const { children, tooltipContent } = params || {};

  const contentRef = useRef<HTMLDivElement>();
  const tooltipRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const scrollHeight = contentRef.current?.scrollHeight ?? 0;
    const clientHeight = contentRef.current?.clientHeight ?? 0;
    toggleIsClamped(scrollHeight > clientHeight);
    // eslint-disable-next-line
  }, [contentRef.current, windowSize]);

  return (
    <HtmlTooltip
      open={isTooltipOpening}
      followCursor
      TransitionComponent={Zoom}
      placement="top-start"
      PopperProps={{
        modifiers: [
          {
            name: "onUpdate",
            fn: ({ state }) => {
              const popperStyles = state.styles.popper;
              toggleFlipped(!isEqual(popperStyles.left, "0"));
            },
          },
        ],
      }}
      title={
        <SHStack
          ref={tooltipRef}
          sx={{
            bgcolor: hexToRGBA(palette.common.white, 0.9),
            border: "1px solid #E3E3E3",
            backdropFilter: "blur(5px)",
            borderRadius: "3px",
            boxShadow: shadows[1],
            position: "relative",
            ml: "-19px",
            p: 1,
            width: "300px",
            left: isFlipped ? "20px" : "0px",
          }}
        >
          <SHTypography variant="body1">{tooltipContent}</SHTypography>
          <SHBox
            sx={{
              position: "absolute",
              bottom: "-11.7px",
              left: isFlipped ? "280px" : "0",
            }}
          >
            <TooltipAllowSVG />
          </SHBox>
        </SHStack>
      }
      onClose={() => openTooltip(false)}
      onOpen={() => {
        if (!isClamped) return;

        openTooltip(true);
      }}
    >
      <SHStack
        ref={contentRef}
        sx={{
          lineBreak: "anywhere",
          WebkitLineBreak: "anywhere",
          overflow: "hidden",
          display: "-webkit-box",
          lineClamp: 1,
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
        }}
      >
        {children}
      </SHStack>
    </HtmlTooltip>
  );
};
