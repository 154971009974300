import { useSupplier } from "@hooks/useSupplier";
import { ViewInvestmentProduct } from "./view";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useIsNew } from "@hooks/useIsNew";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { useUserPermissions } from "@hooks/userUserPermission";
import AccessDeniedDialog from "@components/dialogs/access-denied";
// import { EditInvestmentProduct } from "./edit";

export const InvestmentProductDetails = () => {
  const {
    productUI: { isEditMode },
  } = useSelector((state: RootState) => state.product);
  const isNew = useIsNew();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { loadSupplier } = useSupplier();
  const { user, isSupplierBasic, canViewProduct } = useUserPermissions();
  const accessDenied = isSupplierBasic
    ? user?.userMetadata?.supplier_id !== supplierId
    : !canViewProduct;

  useEffect(() => {
    if (supplierId && (isNew || isEditMode)) {
      loadSupplier(supplierId);
    }
    // eslint-disable-next-line
  }, [supplierId]);

  if (accessDenied) return <AccessDeniedDialog />;

  // if (isNew || isEditMode) return <EditInvestmentProduct/>;
  return <ViewInvestmentProduct/>;
};
