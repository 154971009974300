import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { RootState } from "@redux/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { UpgradeSubscriptionView } from "../components/common";
import { ViewFeatureModel } from "../components/view-feature-model";

export const ViewModelsTab = () => {
  const { isAdviserTrialGroup } = useUserPermissions();
  const {
    investmentProductModelsData,
    investmentProductUI: { isModelsDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { investmentProductId } = useParams();
  const { loadInvestmentProductModels } = useInvestmentProduct();

  useEffect(() => {
    if (isAdviserTrialGroup) return;

    loadInvestmentProductModels(investmentProductId ?? "new", "view");
    // eslint-disable-next-line
  }, [investmentProductId]);

  return isAdviserTrialGroup ? (
    <UpgradeSubscriptionView />
  ) : (
    <ViewFeatureModel
      investmentProductModelsData={investmentProductModelsData}
      isModelsDataLoading={
        Boolean(investmentProductModelsData) ? isModelsDataLoading : true
      }
    />
  );
};
