import { SHBox, SHBoxProps, SHTypography } from "@components/design-systems";
import { alpha, darken, Tooltip } from "@mui/material";

interface SinglePercentageBarChartProps extends SHBoxProps {
  leftValue?: number | string;
  rightValue?: number | string;
  leftBgColor?: string;
  rightBgColor?: string;
  leftLabel?: string;
  rightLabel?: string;
}

export const SinglePercentageBarChart = ({
  leftValue,
  rightValue,
  leftBgColor = "#07A274",
  rightBgColor = "#0EC48F",
  leftLabel,
  rightLabel,
  ...props
}: SinglePercentageBarChartProps) => {
  const isShowLeftLabel = Number(leftValue) > 9;
  const isShowRightLabel = Number(rightValue) > 9;

  const parseDisplayWidth = (value: number | string | undefined) => {
    if (Number(value) >= 9) {
      return value;
    }
    return 5;
  };

  const leftFlex = parseDisplayWidth(leftValue);
  const rightFlex = parseDisplayWidth(rightValue);

  return (
    <SHBox {...props} display="flex" gap={0.3} overflow={"hidden"}>
      {/* Left side */}
      <Tooltip
        title={
          <SHTypography variant="caption" color={"white"}>
            {leftLabel}: {leftValue}%
          </SHTypography>
        }
        arrow
        placement="top"
        enterDelay={0}
        enterNextDelay={100}
      >
        <SHBox
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={leftBgColor}
          sx={{
            flex: `${leftFlex} 1 0`,
            transition:
              "flex 1s ease-in-out, background-color 0.2s ease-in-out",
            "&:hover": {
              bgcolor: darken(leftBgColor, 0.2),
            },
          }}
        >
          {isShowLeftLabel && (
            <>
              <SHTypography variant="caption" color="white">
                {leftValue}%
              </SHTypography>
              <SHTypography lineHeight={1} variant="caption" color="white">
                {leftLabel}
              </SHTypography>
            </>
          )}
        </SHBox>
      </Tooltip>
      {/* Right side */}
      <Tooltip
        title={
          <SHTypography variant="caption" color={"white"}>
            {rightLabel}: {rightValue}%
          </SHTypography>
        }
        arrow
        placement="top"
        enterDelay={0}
        enterNextDelay={100}
      >
        <SHBox
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          bgcolor={rightBgColor}
          sx={{
            flex: `${rightFlex} 1 0`,
            transition:
              "flex 1s ease-in-out, background-color 0.2s ease-in-out",
            "&:hover": {
              bgcolor: alpha(rightBgColor, 0.7),
            },
          }}
        >
          {isShowRightLabel && (
            <>
              <SHTypography fontWeight={600} variant="caption">
                {rightValue}%
              </SHTypography>
              <SHTypography lineHeight={1} fontWeight={600} variant="caption">
                {rightLabel}
              </SHTypography>
            </>
          )}
        </SHBox>
      </Tooltip>
    </SHBox>
  );
};
