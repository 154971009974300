import { GraySuitabilityHubSVG } from "@components/svgs";
import { useState } from "react";
import { SHBox } from "../sh-box";
import SHSkeleton from "../sh-skeleton";

export interface SHImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}
  
export const SHImage = (props: SHImageProps) => {
  const { id, className, src, alt, style, width, height } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadFailed, setIsLoadFailed] = useState(false);

  if (isLoadFailed)
    return (
      <SHBox
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <GraySuitabilityHubSVG
          width={width}
          height={height}
          style={style}
          id={id}
          className={className}
        />
      </SHBox>
    );

  return (
    <>
      {isLoading && (
        <SHSkeleton
          width={width}
          height={height}
          style={style}
          id={id}
          className={className}
        />
      )}
      <img
        {...props}
        src={src || ""}
        alt={alt || "image"}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoadFailed(true)}
        style={{
          ...style,
          display: style?.display,
          objectFit: style?.objectFit ?? "cover",
          objectPosition: style?.objectPosition ?? "center",
        }}
      />
    </>
  );
};
