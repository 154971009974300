import {
  SHDotGroup,
  SHDotGroupProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { useCollapseMenu } from "@hooks/useCollapseMenu ";
import { HighlightType } from "@models/configuration";
import {
  InvestmentProductModelsDTO
} from "@models/product/investment-product/entities/model";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { SxProps, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";

export interface ProductCollapseMenuProps {
  investmentProductModelsData?: InvestmentProductModelsDTO[];
  selectedIndex?: [number, number]; //[sectionIndex,subSectionIndex]
  defaultExpandIndexes?: number[];
  onChange?: (index: [number, number]) => void;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  sx?: SxProps;
}
export const InvestmentProductCollapseMenu = ({
  investmentProductModelsData,
  selectedIndex = [0, 0],
  defaultExpandIndexes = [],
  onChange = (index) => {},
  dotGroupProps,
  sx = {},
}: ProductCollapseMenuProps) => {
  const { palette } = useTheme();
  const { expandedIndex, handleCollapseToggle } =
    useCollapseMenu(defaultExpandIndexes);
  return (
    <>
      {investmentProductModelsData?.map((group, sectionIndex) => {
        const emptyModel = isEmpty(group.models);
        const isExpanded = expandedIndex.includes(sectionIndex);
        return (
          <SHCollapse
            key={group.id}
            title={
              <SHStack direction="row" spacing={0.5} alignItems="start">
                <SHTypography variant="subtitle1">{group.name}</SHTypography>
                <SHDotGroup
                  blueDot={
                    dotGroupProps?.blueDot &&
                    group.dataStatus === InvestmentDataStatus.MissingData
                  }
                  redDot={
                    dotGroupProps?.redDot &&
                    group.highlightType === HighlightType.Edited
                  }
                  paddingTop={"5px"}
                />
              </SHStack>
            }
            open={isExpanded}
            onCollapse={(open) => {
              handleCollapseToggle(sectionIndex, open);
            }}
            width={"100%"}
            height="auto"
            sx={{
              padding: "20px 10px",
              cursor: "pointer",
              bgcolor: isExpanded ? palette.common.white : "transparent",
              borderColor: palette.secondary[100],
              borderBottomStyle: "solid",
              borderBottomWidth: isExpanded ? 0 : 1,
              ...sx,
            }}
          >
            {emptyModel ? (
              <SHTypography
                variant="body2"
                sx={{
                  padding: "20px 10px",
                }}
              >
                No models
              </SHTypography>
            ) : (
              group.models?.map((models, subSectionIndex) => {
                const selectedSubSection =
                  selectedIndex[0] === sectionIndex &&
                  selectedIndex[1] === subSectionIndex;

                return (
                  <SHStack
                    key={models.id}
                    sx={{
                      padding: "20px 10px",
                      cursor: "pointer",
                      bgcolor: selectedSubSection
                        ? hexToRGBA(palette.primary.light, 0.1)
                        : "transparent",
                      "&:hover": {
                        bgcolor: selectedSubSection
                          ? hexToRGBA(palette.primary.light, 0.15)
                          : hexToRGBA(palette.common.white, 0.5),
                      },
                    }}
                    onClick={() => {
                      onChange([sectionIndex, subSectionIndex]);
                    }}
                    direction="row"
                    spacing={0.5}
                    alignItems="start"
                  >
                    <SHTypography
                      variant={selectedSubSection ? "subtitle2" : "body1"}
                    >
                      {models.name}
                    </SHTypography>
                    <SHDotGroup
                      blueDot={
                        dotGroupProps?.blueDot &&
                        models.dataStatus === InvestmentDataStatus.MissingData
                      }
                      redDot={
                        dotGroupProps?.redDot &&
                        models.highlightType === HighlightType.Edited
                      }
                      paddingTop={"5px"}
                    />
                  </SHStack>
                );
              })
            )}
          </SHCollapse>
        );
      })}
    </>
  );
};
